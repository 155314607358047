@import 'styles/colors';

.bottom-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba($color: $sesame-black, $alpha: 0.2);

  transition: background-color 0.2s ease-in-out;

  .animation-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    min-height: 225px;
    height: fit-content;
    width: 100%;
    border-radius: 15px 15px 0 0;
    background-color: $secondary-white;
    z-index: 50;
    opacity: 1;

    &.animate-bottom {
      transition: bottom 0.1s cubic-bezier(0.62, 0.9, 0.17, 0.38);
    }

    .icon {
      position: absolute;
      top: 8px;
      left: 50%;

      transform: translateX(-50%);
    }
  }
}

.draggable-placeholder {
  width: 100%;
  height: 30px;
  background-color: $secondary-white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  // box-shadow: 0px 30px 40px 12px $dark-gray;
  z-index: 50;
}

.bottom-menu {
  width: 100%;
  height: 100%;
  padding: 0 30px 40px 30px;
  background-color: $secondary-white;
  z-index: 50;
  overflow-y: auto;

  &--heading {
    text-align: center;
  }

  &--hr {
    width: 100%;
    height: 1px;
    background-color: $light-gray;
  }
}
