@import 'styles/colors';

.link {
  text-decoration: none;
  transition: all 0.3s ease;
  text-overflow: ellipsis;
  overflow: hidden;

  &.underlined {
    text-decoration: underline;
  }

  &:visited,
  &:link {
    color: inherit;
  }

  &:active,
  &:hover {
    text-decoration: none;
    color: $friendly-green;
  }

  &.green {
    color: $friendly-green;
  }

  &.red {
    color: $friendly-red;
  }

  &.noHoverColor {
    &:active,
    &:hover {
      color: inherit;
    }
  }

  &.disabled {
    cursor: unset;

    &:active,
    &:hover {
      color: inherit;
    }
  }
}
