@import 'styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  // align-items: center;

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .page-cell,
    .cell-dots {
      width: 33px;
      height: 38px;
      color: $friendly-green;
      font-size: 16px;
      text-align: center;
      line-height: 38px;
    }

    .page-cell {
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: $concrete;
      }

      &.current {
        font-weight: 900;
      }
    }

    .cell-dots {
      pointer-events: none;
    }
  }

  .empty-placeholder {
    width: 100%;
    height: 66px;
  }
}
