@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  .form-wrapper {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close {
        font-weight: 600;
        color: $friendly-green;
        cursor: pointer;
      }
    }

    .admins-wrapper {
      .title {
        font-size: 13px;
        color: $dark-gray;
      }

      .admin-row {
        display: flex;
        align-items: center;

        &.email-row {
          border-radius: 10px;
          background-color: $concrete;
          padding: 12px 10px;
        }

        .avatar {
          width: 44px;
          height: 42px;
          border-radius: 5px;
        }

        .credentials {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          max-width: 60%;

          .email {
            color: $dark-gray;
            font-size: 13px;
          }
        }
      }
    }

    .add-person-wrapper {
      display: flex;
      align-items: center;
      width: fit-content;

      cursor: pointer;

      transition: opacity 0.1s ease;

      &:hover {
        opacity: 0.9;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 42px;

        background-color: $friendly-green-03;
        border-radius: 10px;
      }

      .invite-member {
        color: $friendly-green;
      }
    }
  }

  .add-button {
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;

    transition: opacity 0.1s ease;

    &:hover {
      opacity: 0.8;
    }

    .text {
      color: $friendly-green;
      margin-top: 3px;

      @include mobile {
        font-size: 16px;
      }
    }
  }

  .invite-modal,
  .remove-modal {
    max-width: 490px;
    z-index: 30;

    @include mobile {
      max-width: 95vw;
    }
  }

  .remove-modal {
    .cancel-button {
      &:hover {
        background-color: $sesame-black !important;
        border-color: $sesame-black !important;
        color: $secondary-white !important;
      }
    }
  }
}
