.jw-title {
  display: none !important;
}

.jw-controls {
  background: transparent !important;

  .jw-display {
    display: none !important;
  }
}
