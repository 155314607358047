@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  min-width: 768px;
  max-width: 1400px;
  margin: auto;
  padding: calc(#{$navbar-browser-height} + 20px) 62px 62px 62px;

  .nav-path {
    display: flex;
    color: $dark-gray;
    margin-bottom: 20px;
  }

  .header {
    height: 300px;
    display: flex;
    flex-direction: row;
    background: $light-gray;
    border-radius: 10px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
    position: relative;

    .unit-draft-badge {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .header-content {
      width: 100%;
      height: 100%;
      display: flex;
      max-width: 1400px;
      margin: auto;
      padding: 30px 40px;
      position: relative;
      align-items: center;
    }

    .header-left-col {
      z-index: 2;

      .header-name {
        margin-bottom: 10px;
        color: #fff;
      }
    }

    .cover-image {
      height: 100%;
      width: auto;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      filter: drop-shadow(0px 1px 30px rgba(255, 255, 255, 0.22));

      @include fadeIn(0.4s);
    }
  }

  .content-wrapper {
  }
}

.dropdown-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  border-color: transparent;
  border-left: 1px solid #dddddd;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #f2f2f2;
  position: relative;
  flex-shrink: 0;

  @include mobile {
    width: 66px;

    svg {
      transform: scale(0.7);
    }
  }

  .chevron-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -42%);

    .chevron {
      padding-top: 2px;
      color: gray;
      transform: rotate(-90deg);
      transition: all 0.3s ease;

      @include mobile {
        width: 20px;
        height: 25px;
      }

      &.rotated {
        transform: rotate(0deg);
      }
    }
  }
}

.unit-select-wrapper {
  display: flex;
}

.select-relative-wrapper {
  position: relative;

  .select {
    user-select: none;
    margin-top: 10px;
    width: 565px;
    height: 104px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
    color: $sesame-black;

    .select-value {
      padding: 15px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .options-wrapper {
    z-index: 1;
    user-select: none;
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 104%;
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.2);

    .option {
      height: 45px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s ease;

      .check {
        color: $friendly-green;
        height: 25px;
        width: 25px;
        margin-right: 5px;
      }

      &:hover {
        color: $sesame-black;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.weekly-lessons-col {
  display: flex;
  flex-direction: row;
  flex: 1 0 120px;
  min-width: 120px;
  align-items: center;
  padding-left: 17px;

  .body-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 25px;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid $light-gray;
    position: relative;

    .exclamation-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .check {
    margin-right: 26px;
  }
}

.buttons-col {
  justify-content: flex-end;

  .chevron-expand {
    cursor: pointer;
    transition: all 0.3s ease;

    &.rotated {
      transform: rotate(180deg);
    }
  }
}

.expanded-week-wrapper {
  padding: 0 17px 17px;
  height: 720px;
  overflow-y: auto;

  .expanded-header {
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    background-color: $dark-gray;
    overflow: hidden;
    height: 140px;

    .header-image {
      width: 188px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .expanded-text-wrapper {
      flex: 1 0 100px;
      padding: 20px 50px;
      color: #fff;
    }
  }
}

.pagination-row {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark-gray;
  margin-top: auto;
  position: absolute;
  width: 100%;
  bottom: 17px;
}

.lesson-plan-col {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  padding-left: 17px;
  flex: 1 0;

  .body-wrap {
    border-bottom: 1px solid $light-gray;
    width: 90%;
    padding: 19px 12px 19px 0;
  }

  .sel-wrap {
    padding: 3px;
  }

  .sel-wrap.active {
    background-color: #fdeff1;
    border-radius: 5px;
    display: inline-flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;

    .heart {
      color: $friendly-red;
      height: 20px;
      width: 20px;
      margin-left: 5px;
    }
  }

  .check {
    margin-right: 13px;
  }
}

.getting-ready-buttons-col {
  display: flex;
  justify-content: flex-end;
}

.tooltip {
  position: absolute;
  display: inline;
  right: 0;
  top: 30%;
  height: 30px;
  width: 30px;
}

.empty-placeholder {
  padding-top: 45px;
  text-align: center;
  color: #54585a;
}

.complete-alert {
  background-color: $joyeful-yellow;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 10px;
  font-weight: 600;

  .warn-icon {
    margin-right: 10px;
  }
}
