@import 'styles/colors';

.lesson-plan-col {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  padding-left: 17px;
  flex: 1 0;

  .body-wrap {
    border-bottom: 1px solid $light-gray;
    width: 100%;
    padding: 26px 17px;
  }

  .sel-wrap {
    padding: 3px;
  }

  .sel-wrap.active {
    background-color: #fdeff1;
    border-radius: 5px;
    display: inline-flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;

    .heart {
      color: $friendly-red;
      height: 20px;
      min-width: 20px;
      width: 20px;
      margin-left: 5px;
    }
  }

  .check {
    margin-right: 13px;
  }
}

.getting-ready-buttons-col {
  display: flex;
  justify-content: flex-end;
}

.lesson-plan-draft-badge {
  align-self: center;
  margin-left: 5px;
}
