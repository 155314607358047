@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  display: flex;
  height: $footer-height;
  background-color: $concrete;
  border: 1px solid $alto;
  color: $silver-chalice;
  align-items: center;
  padding: 0 62px;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;

  &.isSmall {
    height: 60px;
  }

  & .isMobile {
    flex-direction: column-reverse;
    justify-content: space-evenly;
    height: 180px;
    padding: 20px 62px;

    .links {
      height: 85px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

    & div {
      margin: 0;
    }
    }
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  & div {
    // TODO: replace when we got links
    margin-left: 28px;
    cursor: pointer;
  }
  & a {
    all: unset;
  }
}

