@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  max-width: 1400px;
}

.header-wrapper {
  padding: $content-padding + $navbar-browser-height $content-padding 0;
  max-width: 1400px;
  margin: auto;
  display: flex;
  align-items: center;

  .avatar {
    width: 55px;
    height: 55px;
    border-radius: 5px;
  }

  .title {
    font-size: 32px;
  }

  .admin-badge {
    margin: 25px 0 0 20px;
  }
}

.profile-wrapper {
  display: flex;
  justify-content: flex-start;
}

.profile {
  &--content-left {
    height: 100%;
    padding: 37px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 700px;

    button {
      margin: 40px 0;
    }
  }

  &--input-group {
    width: 100%;
    display: flex;
    justify-content: space-between;

    input,
    div {
      max-width: 300px;
    }
  }

  &--content-right {
    height: 100%;
    padding: 37px;

    .image-wrapper {
      width: 200px;
      min-height: 230px;
      margin: 0 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      p {
        margin: 15px 0;
      }

      &--button {
        margin: 15px 0;
        width: 170px;
      }
    }
  }

  &-fields {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &--left-col {
    margin-right: 40px;
    flex-basis: 300px;
    flex-shrink: 0;
  }

  &--right-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 300px;
    flex-shrink: 0;
  }
}

.title-select {
  width: 100px;
}

.classroom-col {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  min-height: 79px;
  padding-left: 17px;

  .classroom-col-image {
    height: 56px;
    width: 56px;
    background: gray;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 10px;
    flex-shrink: 0;
    margin-right: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
      height: 100%;
    }

    .placeholder {
      width: 44px;
      height: 44px;
      border-radius: 10px;
    }
  }

  .classroom-name-col {
    padding: 28px 17px 28px 0;
    width: 100%;
    border-bottom: 1px solid $alto;
    overflow: hidden;
  }
}

.level-column {
  display: flex;
  align-items: center;
}

.current-spot-column {
  .link {
    display: flex;
    align-items: center;
  }
}

.teachers-col {
  display: flex;
  flex-flow: row-reverse nowrap;
  padding-left: 20px;

  .teacher-avatar {
    margin-left: -20px;
  }
}

.buttons-column {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.add-classroom-border {
  margin-top: 47px;
  border: 2px dashed $light-gray;
  border-radius: 10px;
}

.classrooms-heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-classroom-table {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: $friendly-green;
  border-radius: 8px;
  height: 90px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &--icon {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    background-color: $light-gray;

    .add-classroom-table--icon {
      transform: scale(1.2);
    }
  }
}

@media screen and (max-width: 1050px) {
  .profile-wrapper {
    flex-direction: column-reverse;
  }
  .profile--content-right {
    padding-bottom: 0;

    .image-wrapper {
      margin: 0;
    }
  }

  .profile--content-left {
    padding: 37px;
  }
}
