@import 'styles/colors';
@import 'styles/variables';

.increment-wrapper {
  display: flex;
  align-items: center;

  .number {
    font-family: Source Sans Pro;
    font-weight: bold;
    min-width: 32px;
    text-align: center;

    @include mobile {
      margin: 0 7px 0 7px !important;
    }
  }

  .btn-circle {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $friendly-green;
    border-radius: 50%;

    transition: all 0.2s ease;
    cursor: pointer;

    svg {
      path,
      polygon {
        transition: all 0.2s ease;
      }
    }

    &:hover {
      background-color: $friendly-green;

      svg {
        path,
        polygon {
          fill: $secondary-white !important;
        }
      }
    }

    &.small {
      width: 30px;
      height: 30px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
