@import 'styles/colors';
@import 'styles/variables';

.content-wrapper {
  width: 100%;
}

.page-tab-nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .nav-items {
    display: flex;
    position: relative;

    .nav-item {
      padding: 15px;
      color: $dark-gray;
      position: relative;

      &.active-tab {
        color: black;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .horizontal-line {
    position: relative;
    margin: 0 3px;
    height: 1px;
    width: 100%;
    border: solid 1px transparent;
    border-top-color: $light-gray;

    hr {
      position: absolute;
      height: 4px;
      background-color: $friendly-green;
      border: none;
      margin: 0;
      transform: translateY(-50%);
      transition: 0.2s ease-in-out;
    }
  }
}

.content-right {
  // mainly for admin page, to be adjusted
  position: absolute;
}
