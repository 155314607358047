$notch-top: env(safe-area-inset-top, 0px);
$notch-bottom: env(safe-area-inset-bottom, 0px);
$notch-left: env(safe-area-inset-left, 0px);
$notch-right: env(safe-area-inset-right, 0px);
$footer-height: 100px;
$navbar-browser-height: 72px;
$content-padding: 62px;

$desktop-breakpoint: 821px;
$mobile-breakpoint: 820px;
$mobile-nav-height: 90px;

@mixin mobile {
  @media (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin fadeIn($duration) {
  opacity: 0;
  animation: fadeIn #{$duration} forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin forcedScrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

// teacher lesson plans
$tl-navbar-height: 80px;
