
.tooltip-trigger {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.tooltip-body-wrapper {
  position: fixed;
  text-align: center;
  
  .tooltip-body {
    display: inline-flex;
    padding: 5px 8px;
    border-radius: 3px;
    color: white;
    background-color: #151515;
    margin: auto;

    &::after {
      content: "";
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid black;
      top: -7px;
      left: 50%;
      transform: translate(-50%);
    }
  }
}