@import 'styles/colors';
@import 'styles/variables';

.modal {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.body-wrapper {
  width: 400px;
  margin: 55px auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.isHidden {
    display: none;
  }

  @include mobile {
    width: 100%;
    margin-bottom: 150px;
    margin-top: 0;
  }

  .date-wrapper {
    width: inherit;
    position: relative;

    .date-input {
      width: 170px;
    }

    .day-picker {
      position: absolute;
      top: 0;
      left: 50%;
      margin-top: 0;
      z-index: 40;
      background: $secondary-white;
      min-width: 680px;

      transform: translateX(-50%);
    }
  }

  &--teachers-text {
    font-family: Source Sans Pro;
    margin-bottom: 5px;
  }

  .horizontal-line {
    width: 100%;
    height: 1px;
    background: $light-gray;
    margin: 30px 0;

    @include mobile {
      margin: 10px 0 20px 0;
    }
  }

  .add--co-teacher {
    display: flex;
    align-items: center;
    margin: 20px 0;

    transition: 0.2s ease-in-out;
    cursor: pointer;

    &--text {
      color: $friendly-green;
      padding: 0 10px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .co-teachers--buttons {
    margin: 15px 0;
    margin-left: auto;
    display: inline-flex;

    &--first {
      margin: 0 5px;
    }
  }
}

.button-wrapper {
  position: relative;

  .submit-button {
    width: 130px;
    margin: 0 30px;
  }
}

.modal-body {
  flex-grow: 1;
  position: relative;
  padding-right: 35%;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 165px);

  .title-wrapper {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding-left: 20px;
  }
}

.preview {
  width: 35%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid #dddddd;
  background: #dddddd20;
  padding-top: 100px;
  transition: all 0.3s ease;
}

.footer {
  border-top: 1px solid #ddd;
  justify-content: center;
  background-color: #fff;
  z-index: 1;
  flex-shrink: 0;
}

.picker-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-height: 100%;
  overflow-y: auto;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mobile-form {
  .mobile-step1 {
    &.isHidden {
      display: none;
    }
  }
  .buttons-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 90px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 30px;
    box-shadow: 0px -8px 15px 0px rgba(0, 0, 0, 0.05);
    background-color: $secondary-white;

    button {
      width: 125px;
    }
  }
}

.preview-modal {
  width: 90vw;
  height: 95vh;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .outer-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .preview-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .close {
        position: absolute;
        border-left: none;
        height: unset;
        top: 25px;
        right: 20px;
      }

      .preview-wrapper {
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: -5rem;
      }
    }
  }
}

.preview,
.preview-modal {
  .preview-card {
    width: 315px;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid #ddd;
    transition: all 0.3s ease;

    @include mobile {
      width: 93%;

      .orange-bar {
        width: 60px !important;
      }

      .bar:last-child {
        margin-left: 10px;
      }
    }

    .top-wrapper {
      width: 100%;
      height: 135px;
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      color: white;
      transition: all 0.3s ease;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #ddd;
    }

    .bottom-wrapper {
      width: 100%;
      height: 110px;
      background-color: #fff;
      padding: 13px 10px;
      position: relative;

      .avatar {
        position: absolute;
        right: 15px;
      }

      .bar {
        background-color: #dadada;
      }

      .pill {
        width: 100%;
        height: 40px;
        padding: 10px 20px 10px 15px;
        border-radius: 20px;
        border: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
