@import 'styles/colors';

.heading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 15px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .toggle-icon {
    margin: 10px 0 30px auto;
    width: 25px;
    height: 25px;
  }

  .favs-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;

    &:not(.list-view) {
      opacity: 0;
      animation-name: tileSlideIn;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }

    &.list-view {
      flex-direction: column;
    }

    .card-wrapper {
      width: 145px;
      height: fit-content;

      display: flex;
      flex-direction: column;

      position: relative;

      @media (max-width: 320px) {
        margin: auto;
      }

      &.list-view {
        width: 100%;
        flex-direction: row;
        align-items: center;
        opacity: 0;

        animation-name: listElementSlideIn;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }

      &--menu {
        position: absolute;
        top: 13px;
        right: 13px;

        width: 40px;
        height: 40px;
        background-color: rgba(84, 88, 90, 0.1);
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        .menu-icon {
          transform: rotateZ(90deg);
        }

        &.list-view {
          right: 0;
          top: 50%;
          background-color: unset;

          transform: translateY(-50%);

          .menu-icon {
            transform: rotateZ(0deg) scale(0.9);
          }
        }
      }

      .card {
        width: 100%;
        min-height: 145px;
        height: fit-content;
        border-radius: 15px;

        background: url('../../../icons/logo/StartWithSesame_V_Full.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-color: $alto;

        &.list-view {
          border-radius: 10px;
          width: 56px;
          min-width: 56px;
          height: 56px;
          min-height: unset;
        }
      }

      .text-wrapper {
        margin: 10px 0 20px 0;

        &.list-view {
          margin: 15px 0 15px 10px;
          width: clamp(145px, 55%, 170px) !important;
        }

        &--name {
          white-space: nowrap;
          font-family: VAG;
        }

        &--desc {
          white-space: nowrap;
          font-family: VAG;
          color: $dark-gray;
        }
      }
    }
  }
}

.menu-option {
  display: flex;
  align-items: center;

  cursor: pointer;

  &--icon {
    margin: 10px 10px 10px 0;
  }
}

@keyframes tileSlideIn {
  0% {
    transform: translateY(15%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes listElementSlideIn {
  0% {
    transform: translateX(25%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
