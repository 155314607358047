@import 'styles/colors';
@import 'styles/variables';

// BORDERS
$regular-radius: 30px;
$round-radius: 50%;

// WIDTHS
$regular-min-width: 70px;
$favorite-width: 125px;
$favorite-marked-width: 140px;
$unmarked-width: 170px;
$marked-width: 142px;
$rounded-markable-width: 23px;
$pagination-small-width: 41px;
$pagination-big-width: 60px;

// HEIGHTS
$big-height: 48px;
$medium-height: 40px;
$small-height: 32px;
$extra-small-height: 24px;

// MISC
$button-horizontal-padding: 20px;

// MIXINS
@mixin variant-styles {
  &--filled {
    background-color: $light-gray;
    border-color: $light-gray;
    color: $sesame-black;
  }

  &--filled-red {
    background-color: $friendly-red;
    border-color: $friendly-red;
    color: $secondary-white;

    &:hover {
      background-color: $secondary-white !important;
      border-color: $friendly-red !important;
      color: $friendly-red !important;
    }
  }

  &--outlined {
    background-color: $secondary-white;
    border-color: $dark-gray;
    color: $dark-gray;

    &:hover {
      background-color: $friendly-green;
      border-color: $friendly-green;
      color: $secondary-white;
    }
  }

  &--black {
    background-color: $sesame-black;
    border-color: $sesame-black;
    color: $secondary-white;

    &:hover {
      color: $secondary-white !important;
      background-color: $friendly-red !important;
      border-color: $friendly-red !important;
    }
  }
}

// STYLES
button {
  padding: 0 $button-horizontal-padding;
  font-size: 13px;
  outline: none;
  background: no-repeat;
  transition: 0.3s ease-in-out;
  font-weight: 600;
  user-select: none;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
  }
}

.regular-button {
  border: 2px solid $friendly-green;
  border-radius: $regular-radius;
  background-color: $friendly-green;
  color: $secondary-white;
  min-width: $regular-min-width;

  &--bg {
    height: $big-height;
    font-size: 15px;

    @include variant-styles;
  }

  &--md {
    height: $medium-height;
    font-size: 15px;

    @include variant-styles;
  }

  &--s {
    height: $small-height;

    @include variant-styles;
  }

  &--xs {
    height: $extra-small-height;

    @include variant-styles;
  }

  @include desktop {
    &:hover,
    &.dropdownActive {
      background-color: $secondary-white;
      border-color: $friendly-green;
      color: $friendly-green;
    }
  }
}

.favorite-button,
.markable-button {
  height: $small-height;
  border: 1px solid $dark-gray;
  color: $dark-gray;
  border-radius: $regular-radius;
  padding: 0 0 0 1rem;
  background-position: 10px 50%;
  font-weight: 400;
  transition: width 0s linear;
}

.favorite-button {
  width: $favorite-width;
  background-image: url('../../icons/FavoriteButton/Unmarked.svg');
  line-height: normal;

  &--marked {
    width: $favorite-marked-width;
    border-color: $orange;
    color: $secondary-white;
    background-color: $orange;
    background-image: url('../../icons/FavoriteButton/Marked_White.svg');
  }

  &--round {
    width: 32px;
    height: 32px;
    padding: 0;
    background-position: 50%;
  }

  @include desktop {
    &:hover {
      border-color: $orange;
      color: $orange;
      background-color: $secondary-white;
      background-image: url('../../icons/FavoriteButton/Hovered.svg');
    }
  }
}

.markable-button {
  min-width: $unmarked-width;
  background-image: url('../../icons/MarkableButton/Unmarked.svg');
  overflow: hidden;
  position: relative;

  &--marked {
    min-width: $marked-width;
    background-color: $friendly-green;
    border-color: $friendly-green;
    color: $secondary-white;
    background-image: url('../../icons/MarkableButton/Marked.svg');

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: rgba(235, 255, 162, 0.7);
      width: 50px;
      height: $big-height;
      top: 0;
      filter: blur(30px);
      transform: translateX($unmarked-width) skewX(-60deg);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: rgba(235, 255, 162, 0.7);
      width: 50px;
      height: $big-height;
      top: 0;
      filter: blur(30px);
      transform: translateX($unmarked-width) skewX(-60deg);
    }

    // &:not(:hover) {
    //   &::before,
    //   &::after {
    //     animation: ShineEffect;
    //     animation-delay: 0.4s;
    //     animation-duration: 0.5s;
    //     animation-iteration-count: 2;
    //   }
    // }

    &::before,
    &::after {
      animation: ShineEffect;
      animation-delay: 0.4s;
      animation-duration: 0.5s;
      animation-iteration-count: 2;
      // animation-play-state: paused;
    }
  }

  &--round {
    border-radius: $round-radius;
    min-width: $rounded-markable-width;
    height: $rounded-markable-width;
    background-position: 50% 50%;
  }

  @include desktop {
    &:hover {
      border-color: $friendly-green;
      color: $friendly-green;
      background-color: $secondary-white;
      background-image: url('../../icons/MarkableButton/Hovered.svg');
    }
  }
}

.pagination-button {
  border: 2px solid $friendly-green;
  border-radius: $round-radius;
  background-color: $friendly-green;
  background-image: url('../../icons/Arrows/LeftWhite.svg');
  background-size: 22px;
  background-position: 50% 50%;
  padding: 0;

  &--right {
    transform: rotate(180deg);
  }

  &--bg {
    width: $pagination-big-width;
    height: $pagination-big-width;
  }

  &--s {
    width: $pagination-small-width;
    height: $pagination-small-width;
    
    &--reversed {
      background-color: white !important;
      color: $friendly-green !important;
      border-color: $friendly-green !important;
      background-image: url('../../icons/Arrows/LeftGreen.svg') !important;
  
      &:hover {
        background-color: $friendly-green !important;
        border-color: $friendly-green !important;
        color: white !important;
        background-image: url('../../icons/Arrows/LeftWhite.svg') !important;
      }
    }

  }

  @include desktop {
    &:hover {
      color: $friendly-green;
      background-color: $secondary-white;
      background-image: url('../../icons/Arrows/LeftGreen.svg');
    }
  }

  &.disabled {
    background-image: url('../../icons/Arrows/LeftWhite.svg') !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
  }
}

// disabled styles
button {
  &.disabled {
    cursor: unset;
    border: solid 2px $light-gray;
    background: $light-gray;
    color: black;

    &:hover {
      border: solid 2px $light-gray;
      background: $light-gray;
      color: black;
    }
  }
}

@keyframes ShineEffect {
  0% {
    transform: translateX($marked-width) skewX(-60deg);
  }
  100% {
    transform: translateX(-120px) skewX(-60deg);
  }
}

.fab-wrapper {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: $friendly-green;
  box-shadow: 0px 3px 15px -6px #000000;

  display: flex;
  justify-content: center;
  align-items: center;
}
