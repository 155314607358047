@import 'styles/colors';

.label-wrapper {
  width: 100%;
  display: flex;
  align-items: center;

  .avatar {
    width: 36px;
    height: 36px;
    border-radius: 5px;
  }

  .name,
  .email {
    font-size: 13px;
  }

  .email {
    color: $dark-gray;

    &.mr-left {
      margin-left: 5px;
    }
  }

  .add {
    color: $friendly-green;
    font-weight: 600;
  }
}

.close-wrapper {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $dark-gray;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  .icon {
    width: 14px;
    height: 14px;

    path {
      fill: $secondary-white;
    }
  }
}

.search-icon {
  cursor: pointer;
}
