@import 'styles/colors';

.lesson-plans-bread-crumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  position: sticky;
  top: 35px;
  left: 30px;
  z-index: 5;

  &--short {
    max-width: 500px;
  }

  &--link-text {
    transition: 0.2s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
  }

  &--arrow {
    margin: 0 3px;
    padding-bottom: 2px;
    font-size: 14px;
    color: $dark-gray;
  }

  &--lesson-name {
    color: $dark-gray;
    opacity: 0.8;
  }
}

.lesson-plans-content-right {
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  position: sticky;
  top: 10px;
  right: 10px;
  z-index: 5;

  &--vl {
    width: 1px;
    height: 48px;
    background-color: $light-gray;
  }

  &--print,
  &--close {
    cursor: pointer;
  }
}
