@import 'styles/colors';

.wrapper {
  display: flex;
  background: #e6e6e6;
  border: 1px solid $alto;
  border-radius: 2px;
  height: 40px;
  width: 79px;
  cursor: pointer;
  user-select: none;

  .col {
    display: flex;
    flex: 1 0;
    justify-content: center;
    align-items: center;
    transition: all .3s ease;

    &.active {
      background: #fff;
    }
  }
}