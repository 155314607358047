@import 'styles/colors';
@import 'styles/variables';

$wrapper-width: 620px;
$wrapper-height: 480px;


.cover-image {
  padding-bottom: 42.94%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #d3d3d330;
}

.experience-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: $wrapper-height;
  border: 1px solid $light-gray;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  .draft-badge {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
  }

  @include mobile {
    min-height: unset;
    border-radius: 15px;
  }

  .mobile-image {
    width: 100%;
  }

  .hr-line {
    width: 100%;
    height: 1px;
    background-color: $light-gray;

    @include mobile {
      display: none;
    }
  }

}

.experience-content--wrapper {
  padding: 17px 20px 17px 56px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mobile {
    padding-left: 50px;
  }

  & > * {
    margin: 3px 0;

    @include mobile {
      margin: 0;
    }
  }

  .experience-text {
    color: $dark-gray;
    width: 100%;

    * {
      font-size: 15px !important;
      font-weight: normal;
    }
    
    @include mobile {
      margin-bottom: 15px;
    }
  }
}

.button-wrapper {
  position: absolute;
  left: 20px;
  @include mobile {
    left: 15px;
  }
}

.buttons-wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #e3e3e3;
  max-height: 60px;
  margin-top: auto;
}

.tags-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;

  .tag {
    margin: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}