@import 'styles/colors';

.button-wrapper {
  .icon-label {
    display: flex;
    align-items: center;

    svg {
      path {
        transition: fill 0.3s ease-in-out;
      }
    }

    &.dropdownActive {
      svg {
        path {
          fill: $friendly-green !important;
        }
      }
    }
  }
}
