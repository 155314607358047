@import 'styles/variables';
@import 'styles/colors';

$image-container-width: 100%;
$image-container-height: 289px;

.wrapper {
  padding: 72px 62px 62px;
  max-width: 1400px;
  min-width: 768px;
  margin: auto;
}

.image-container {
  width: $image-container-width;
  height: $image-container-height;
  border-radius: 10px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  .week-draft-badge {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
  }

  .image-overflow {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    img {
      min-width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .color-bar {
    width: 100%;
    height: 12px;
    background-color: $purple;
  }
}

.week-nav-content {
  padding-top: 80px;
  position: relative;
  text-overflow: ellipsis;

  display: flex;

  &--left {
    width: 70%;
  }

  &--right {
    width: 30%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .button-date-container {
      position: absolute;
      bottom: -131px;
      right: 0;

      display: flex;
      align-items: center;

      &--expanded {
        height: 35px;
        position: fixed;
        top: calc(#{$navbar-browser-height} + 20px);
        right: 62px;
        z-index: 21;

        animation: fadeIn 1s normal;
      }

      button {
        margin-right: 15px;

        &.preview {
          padding: 0;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;

        p {
          margin-bottom: 10px;
        }

        button {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;

    &--right {
      .heart-box {
        width: 100%;
        min-height: unset;
        padding: 40px 20px 20px;
        margin-top: 40px;
      }
    }

    &--left,
    &--right {
      width: 100%;
    }
  }

  &--heading {
    color: $purple;
    margin-bottom: 10px;

    &--expanded {
      position: fixed;
      top: calc(#{$navbar-browser-height});
      display: flex;
      align-items: flex-end;
      left: 60px;
      max-width: 30%;
      z-index: 21;
      min-height: 60px;
      font-size: 20px;
      color: $sesame-black !important;
      padding-bottom: 10px;
      animation: fadeIn 0.8s normal;
    }
  }

  &--text {
    font-family: Source Sans Pro;
  }
}

.owner {
  color: rgb(136, 138, 140);
}

.content-left--nav-section {
  color: $dark-gray;
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  opacity: 0.7;
  z-index: 2;

  & .content-left--nav-section--arrow {
    text-decoration: none !important;
    margin: 0px 5px;
  }

  a:not(:last-child) {
    text-decoration: underline;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
