@import 'styles/colors';
@import 'styles/variables';

$arrow-size: 32px;
$action-section-min-height: 80px;
$content-max-width: 695px;
$cell-size: 44px;
$desktop-container-radius: 8px;

// MOBILE
$mobile-breakpoint: 768px;
$mobile-container-radius: 15px;
$mobile-width: 90%;
$mobile-wrapper-height: 400px;
$mobile-action-section-height: 120px;
$exit-button-size: 25px;

.picker-content-wrapper {
  min-height: 450px;
  margin-top: 50px;
  max-width: $content-max-width;
  outline: none;
  display: flex;
  flex-direction: column;
  border-radius: $desktop-container-radius;
  box-shadow: 0px 1px 22px 0px $light-gray;

  @media (max-width: $mobile-breakpoint) {
    min-height: auto;
    border-radius: $mobile-container-radius;
    position: relative;
    margin-top: 0;
    box-shadow: none;
  }
}

.action-section {
  min-height: $action-section-min-height;
  padding: 17px 23px 0 23px;
  display: flex;
  justify-content: space-between;

  @media (max-width: $mobile-breakpoint) {
    background-color: $friendly-green;
    border-top-left-radius: $mobile-container-radius;
    border-top-right-radius: $mobile-container-radius;
    color: $secondary-white;
    height: $mobile-action-section-height;
  }

  .exit-button {
    position: absolute;
    width: $exit-button-size;
    height: $exit-button-size;
    top: 17px;
    right: 20px;
    background: url('../../icons/Misc/Cross.svg') no-repeat;
    background-size: $exit-button-size;
  }
}

.action-section .content-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .start-date-year {
    opacity: 0.6;
    margin: 4px 0;
  }
}

.action-section .content-right {
  margin: auto 0;
  width: 200px;
  display: flex;
  justify-content: space-around;

  @media (max-width: $mobile-breakpoint) {
    position: absolute;
    width: inherit;
    right: 22px;
    bottom: 21px;
    z-index: 110;
  }
}

.start-date-header {
  margin: 3px 0;
  font-weight: 50;
  font-family: VAG;
}

.day-picker {
  z-index: 100;
}

.day-picker .DayPicker-wrapper {
  outline: none;

  @media (max-width: $mobile-breakpoint) {
    height: $mobile-wrapper-height;
  }
}

.day-picker .DayPicker-Month {
  border-collapse: separate;
}

.day-picker .DayPicker-WeekNumber {
  outline: none;
}

.day-picker .DayPicker-Day {
  outline: none;
  position: relative;
  width: $cell-size;
  min-height: $cell-size;
  height: $cell-size;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 550;

  @include mobile {
    font-family: Source Sans Pro;
    font-weight: normal;
    font-size: 12px;
  }
}

.day-picker .DayPicker-Day--today {
  font-weight: 550;
  color: black;

  &:not(.DayPicker-Day--selectedRange):not(.DayPicker-Day--hoverRange) {
    box-shadow: 0 0 3px $dark-gray;
  }
}

.day-picker .DayPicker-Day--hoverRange {
  background-color: $friendly-green-03 !important;
  border-radius: 0;

  &Start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  &End {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

.day-picker .DayPicker-Day--selectedRange {
  color: $friendly-green !important;
  background-color: $friendly-green-03 !important;
  border-radius: 0;
}

.day-picker .DayPicker-Day--selectedRangeStart {
  color: white !important;
  background-color: $friendly-green !important;
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: $cell-size;
    background-color: $friendly-green-03;
    z-index: -100;
  }
}

.day-picker .DayPicker-Day--selectedRangeEnd {
  border-radius: 0 50% 50% 0;
}

// navigation section
.day-picker .DayPicker {
  &-Caption {
    text-align: center;
    margin-bottom: 20px;

    div {
      font-family: Source Sans Pro;
      font-size: 18px;
    }

    @include mobile {
      font-size: 14px;
    }
  }

  &-NavButton--prev {
    left: 25px;
    background-image: url('../../icons/Arrows/LeftBlack.svg');
    background-size: $arrow-size;

    @include mobile {
      background-image: url('../../icons/chevron-grey.svg');
      opacity: 0.6;
    }
  }

  &-NavButton--next {
    right: 25px;
    background-image: url('../../icons/Arrows/LeftBlack.svg');
    transform: rotate(180deg);
    background-size: $arrow-size;

    @include mobile {
      background-image: url('../../icons/chevron-grey.svg');
      opacity: 0.6;
    }
  }
}
