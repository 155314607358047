@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  background-color: $wild-sand;
  min-height: calc(100vh - #{$tl-navbar-height + 3 / 2 * $footer-height});
  padding: 60px calc((100% - 1400px) / 2) 250px calc((100% - 1400px) / 2);
  display: flex;
  min-width: 768px;

  &.is-ie {
    justify-content: flex-end;
  }

  .sticky-menu {
    position: sticky;
    top: 150px;
    left: 60px;
    width: 100%;
    max-height: 300px;
    font-size: 15px;
    line-height: 30px;

    flex: 1;

    @media screen and (max-width: 1100px) {
      display: none;
    }

    &.is-ie {
      position: absolute;
      top: 130px;
      left: 20px;
      max-width: 300px;
      flex: none;
    }

    &--text {
      color: $dark-gray;
      padding: 3px 0;
      transition: 0.2s ease-in-out;

      &-active {
        color: $friendly-green;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }

  .content-container {
    width: 100%;
    min-width: 150px;
    height: 100%;
    background-color: $secondary-white;
    border: solid 1px $light-gray;
    border-radius: 10px 10px 5px 5px;
    overflow: hidden;

    flex: 4;

    &.is-ie {
      width: 70%;
      flex: none;
      margin-top: 130px;
    }

    &--upper-bar {
      width: 100%;
      height: 12px;
      border-radius: 5px 5px 0 0;
    }
  }

  .main-section {
    padding: 50px 100px;

    &--chapter {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      line-height: 26px;
      color: #151515;
      position: relative;
      margin-top: 50px;

      &-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        margin: 15px 0;
        line-height: 40px;

        &--icon {
          width: 25px;
          height: 25px;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-150%, 25%);
        }

        &--button {
          min-width: 140px;
          margin-left: auto;
        }
      }
    }

    &--image-wrapper {
      position: absolute;
      right: 0;
      top: 15px;
      border-radius: 5px;

      .image-caption {
        font-family: Source Sans Pro;
        line-height: 20px;
        color: $dark-gray;
      }
    }

    &--sel-header {
      margin: 50px 0 30px 0;
    }

    &--pre_k3 {
      table:not(:global(.sel_related)) {
        thead {
          background-color: $purple;
        }
      }
    }

    &--pre_k4 {
      table:not(:global(.sel_related)) {
        thead {
          background-color: $thoughtful-teal;
        }
      }
    }

    &--mixed {
      table:not(:global(.sel_related)) {
        thead {
          background-color: $blue;
        }
      }
    }
  }

  ul {
    list-style-type: none;
  }

  ul:not([style]) li {
    position: relative;
  }

  ul:not([style]) li::before {
    content: '';
    display: inline-block;
    height: 10px;
    width: 10px;
    border: 1px solid;
    border-radius: 2px;
    position: absolute;
    left: -20px;
    top: 7px;
  }
}

.print-heading {
  display: none;
  margin-bottom: 50px;

  &--crumbs {
    color: $dark-gray;
    opacity: 0.7;
    margin-bottom: 5px;
  }
}
