@import 'styles/colors';

.wrapper {
  padding: 120px 62px 62px;
  max-width: 1400px;
  margin: auto;
  min-width: 768px;

  .classrooms-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.classrooms-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin-right: -40px;

  .card {
    display: flex;
    flex-direction: column;
    flex: 1 0 420px;
    height: 443px;
    margin: 0 40px 40px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;

    .image-content {
      display: flex;
      height: 100%;
      width: 100%;
      z-index: 2;

      .classroom-name {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 15px;
        z-index: inherit;

        &__white {
          color: #fff;
        }
      }
    }

    .dropdown {
      margin-left: auto;
    }

    &.add-classroom {
      color: $friendly-green;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      margin: 0;
      border: none;

      .add-icon {
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        background-color: $light-gray;

        .add-icon {
          transform: scale(1.2);
        }
      }
    }

    .image {
      padding: 20px;
      min-height: 265px;
      max-height: 273px;
      position: relative;
      z-index: 0;
      cursor: pointer;
      overflow: hidden;

      .image-element {
        position: absolute;
        top: 0;
        left: 50%;
        max-height: 265px;
        z-index: -1;
        height: 100%;
        transform: translateX(-50%);
        transition: transform 1s ease;
      }

      &:hover .image-element {
        transform: translate(-50%, -10%) scale(1.2);
      }

      &.draft {
        cursor: unset;
      }
    }

    .image-backdrop {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 80%);
      pointer-events: none;
    }

    .body {
      display: flex;
      padding: 17px 23px;
      flex: 1 0;

      .title-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: 0;

        .title-col {
          flex: 1 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 70%;
        }
      }

      .teachers-col {
        display: flex;
        flex-flow: row-reverse nowrap;
        padding-left: 20px;

        .teacher-avatar {
          margin-left: -20px;
        }
      }
    }

    .footer {
      padding: 14px 25px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #ddd;
      bottom: 0;
    }
  }
  .add-classroom-border {
    margin: 0 40px 40px 0;
    border: 2px dashed $light-gray;
    border-radius: 5px;
    flex: 1 0 420px;
  }

  .classroom-aligner {
    flex: 1 0 420px;
    border-color: transparent;
  }
}

.not-started {
  width: 77px;
  height: 22px;
  padding: 3px 0;
  text-align: center;
  color: #737373;
  white-space: nowrap;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #737373;
  text-transform: uppercase;
}

.current-spot {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.week-link {
  white-space: nowrap;
}

.classroom-col {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 17px;

  .classroom-col-image {
    height: 56px;
    width: 56px;
    background: gray;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 10px;
    flex-shrink: 0;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .image {
      height: 100%;
    }
  }

  .classroom-name-col {
    padding: 28px 0;
    width: 100%;
    border-bottom: 1px solid $alto;
    overflow: hidden;
  }
}

.unit-column {
  display: flex;
  flex-direction: column;
  align-items: inherit !important;
  justify-content: center;
}

.current-spot-column {
  .link {
    display: flex;
    align-items: center;
  }
}

.buttons-column {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .table-dropdown {
    background: transparent;
    margin-left: 20px;
    svg {
      color: $sesame-black !important;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.add-classroom-table {
  margin-top: 47px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: $friendly-green;
  border: 2px dashed $light-gray;
  border-radius: 10px;
  height: 90px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all ease;

  .add-classroom-table--icon {
    transition: 0.2s all ease;
  }

  &:hover {
    background-color: $light-gray;

    .add-classroom-table--icon {
      transform: scale(1.2);
    }
  }
}

.placeholder-classroom {
  position: relative;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddd;

  .text-wrapper {
    position: absolute;
    left: 25px;
    bottom: 25px;
  }

  .set-up-classroom {
    width: 190px;
    height: 48px;
    border-radius: 30px;
    background-color: rgba(21, 21, 21, 0.7);

    font-family: Source Sans Pro;
    font-size: 18px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 2px solid transparent;

    &:hover {
      border-color: white;
    }
  }
}

.fake-class {
  font-family: Source Sans Pro;
  font-size: 13px;
  position: relative;

  .remove-fake {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 174px;
    height: 29px;
    border-radius: 5px;
    background-color: rgba(21, 21, 21, 0.7);
    color: #ffffff;
    line-height: 24px;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      text-decoration: underline solid rgb(255, 255, 255);
      margin-left: 5px;
    }
  }
}

.school-select {
  height: 104px !important;
  width: 600px !important;
}

.school-value-wrapper {
  display: flex;
  align-items: center;
  padding: 15px;

  .avatar {
    height: 60px;
    width: 60px;
    margin-right: 15px;
  }
}
