@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 350px;
  max-height: 350px;
  overflow-y: scroll;

  @include mobile {
    padding: 0 !important;
  }

  .printable-box {
    margin: 10px 0;
  }

  .no-printables {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 15px;

    color: $dark-gray;
  }
}
