@import 'styles/colors';
@import 'styles/variables';

h1,
h2,
h3 {
  font-weight: inherit;
  font-family: VAG;

  * {
    font-family: VAG;
  }
}

.nav-button--lesson-plans-mobile {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $friendly-green;
  box-shadow: 0 2px 5px 0 $dark-gray;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10;
}

.table-wrapper {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  margin: 25px 0;

  @include forcedScrollbar;
}
