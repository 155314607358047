@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  width: 100%;
  min-height: calc(100vh - #{$tl-navbar-height + 3 / 2 * $footer-height});
  background-color: $wild-sand;
  padding: 60px calc((100% - 1400px) / 2);

  &.is-ie {
    min-height: calc(100vh - #{$footer-height});
    margin-top: 120px;
    padding: 70px calc((100% - 1400px) / 2);
  }

  @include mobile {
    background-color: $secondary-white;
    padding: 0 !important;
  }

  .section-container {
    min-height: 500px;
    background-color: $secondary-white;
    border: solid 1px $light-gray;
    border-radius: 10px 10px 5px 5px;
    overflow: hidden;

    @include mobile {
      border: none;
    }

    .printables-container {
      padding: 80px 110px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include mobile {
        padding: 0;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
      }

      &--printable {
        width: 520px;
        margin: 15px 0;

        @include mobile {
          width: 100%;
        }
      }
    }

    &--upper-bar {
      width: 100%;
      height: 12px;

      @include mobile {
        display: none;
      }
    }
  }
}

.no-printables {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: $dark-gray;

  @include mobile {
    padding-top: 50px;
  }
}
