@import 'styles/colors';

.dropdown-wrapper {
  position: absolute;
  z-index: 1;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.2s;
  border: 1px solid $light-gray;
  text-align: left;
  min-width: 210px;
  overflow: hidden;
  display: none;

  &.is-open {
    display: block;
  }

  .list-ul {
    margin: 0;
    padding: 0;

    .list-li {
      display: flex;
      align-items: center;
      width: 100%;
      font-weight: 400;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: $light-gray;
      }

      &:focus {
        outline: none;
      }

      .label {
        padding: 10px 20px;
        color: #000;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
    }

    .list-li:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }
  }

  &.is-hover {
    min-width: 260px;

    .list-ul {
      .list-li {
        border-bottom: none;

        &:hover {
          background-color: $concrete;
        }
      }
    }
  }
}
