@import 'styles/colors';

.modal-wrapper {
  padding: 30px;
  position: relative;
  line-height: 24px;
  text-align: center;

  &.isArrowed {
    ::after {
      content: '';
      position: absolute;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid white;
      bottom: -15px;
      left: 50%;
      transform: translate(-50%);
    }
  }
}

.ios-share {
  width: 40px;
  color: #0076fc;
  display: inline-flex;
  margin-bottom: -12px;
}

.android-share {
  display: inline-flex;
  margin-bottom: -10px;

  transform: rotate(90deg);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
