.favorites-toggle-view {
  position: absolute;
  top: 130px;
  right: 20px;
  padding: 10px;

  &--icon {
    width: 25px;
    height: 25px;
  }
}
