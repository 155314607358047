@import 'styles/colors';
@import 'styles/variables';

.component-wrapper {
  width: 100%;
  position: absolute; //TODO: might need to be relaited
  top: 0;
  left: 0;

  .heading-wrapper {
    width: 100%;
    height: 85px;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: $secondary-white;

    z-index: 10;

    &.fixed {
      position: fixed;
    }

    .go-back {
      position: absolute;
      top: 43px;
      left: 15px;
    }

    .heading {
      margin-top: 48px;
      max-width: 60%;
      text-align: center;
      white-space: nowrap;
    }
  }

  .content-wrapper {
    width: 100%;
    height: 100%;
    background-color: $secondary-white;
    padding: 25px 30px;

    position: relative;
  }
}
