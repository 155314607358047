@import 'styles/colors';

.content-wrapper {
  width: 100%;
  height: 100%;
  background-color: $secondary-white;

  .header-nav-wrapper {
    width: 100%;
    position: fixed;
    top: 0;
    left: 50%;
    background-color: $secondary-white;
    padding: 30px 30px 10px 30px;

    z-index: 20;
    transform: translateX(-50%);
  }

  .page-tab-nav {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    &.narrow {
      justify-content: center;
    }

    .nav-item {
      width: fit-content;
      height: 30px;
      padding: 0 10px;
      color: $sesame-black;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: 600;

      white-space: nowrap;

      &.active-tab {
        background-color: $friendly-green-03;
        color: $friendly-green;

        animation-name: backgroundFadeIn;
        animation-duration: 0.3s;
      }

      &.wide {
        width: 100px;
      }
    }
  }
}

@keyframes backgroundFadeIn {
  0% {
    background-color: $secondary-white;
  }
  100% {
    background-color: $friendly-green-03;
  }
}
