@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  @include mobile {
    width: 90vw;
    height: 95vh;
    border-radius: 5px;
  }

  strong {
    font-family: VAG;
  }

  width: 720px;
  min-height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .body-wrapper {
    min-height: 355px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-bottom: auto;

    @include mobile {
      padding: 20px;

      h4 {
        font-size: 22px;
        line-height: 32px;
      }
    }

    .image {
      width: 225px;
      height: 225px;
      border: 1px solid $light-gray;
      border-radius: 50%;
      text-align: center;
      line-height: 225px;
      color: $light-gray;

      transition: transform 0.2s ease;

      &.lift {
        transform: translateY(-20px);

        @include mobile {
          transform: none;
        }
      }
    }
  }

  .footer-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .nav-button {
      transition: all 0.2s ease;
      opacity: 1;

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }

      &.forward {
        transform: rotate(180deg);
        transition-duration: 0.4s;
      }

      &.accept {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @include mobile {
          right: -10px;
        }
      }
    }

    .dots-wrapper {
      display: flex;
      align-items: center;

      .dot {
        width: 15px;
        height: 15px;
        border: 1px solid $sesame-black;
        border-radius: 50%;
        margin: 0 4px;

        transition: background-color 0.2s ease;

        &.current {
          background-color: $sesame-black;
        }
      }
    }
  }
}
