@import 'styles/colors';

.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &--icon {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 40px;
    left: 15px;
  }
}

.section {
  margin-top: 110px;

  .row-texts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .classroom-name {
      max-width: 200px;
      white-space: nowrap;
    }
  }

  .instance-image-wrapper {
    width: 170px;
    height: 170px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    .image {
      width: 100%;
      height: 100%;
      background-color: $friendly-red;
      line-height: 170px;
      text-align: center;
      color: white;
      font-size: 32px;
      font-family: VAG;

      &.no-bg {
        background-color: unset;
      }
    }

    .icon {
      position: absolute;
      top: 10px;
      left: 10px;
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
    }
  }

  .licenses-wrapper {
    width: 100%;
    padding: 25px 20px;
    border: 1px solid $alto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .hr {
      width: 100%;
      height: 1px;
      background-color: $alto;
    }

    .contact-span {
      color: $friendly-green;
      cursor: pointer;
    }
  }

  .btn-add {
    svg {
      path {
        transition: fill 0.3s ease-in-out;
      }
    }

    &:hover {
      svg {
        path {
          fill: $friendly-green !important;
        }
      }
    }

    .add-label {
      display: flex;
      align-items: center;
    }
  }

  .admins-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.menu-fab {
  position: fixed;
  right: 30px;
  bottom: 110px;
}

.drawer-menu {
  display: flex;
  flex-direction: column;

  .drawer-option {
    display: flex;
    align-items: center;
    margin: 8px 0 8px 0;
    cursor: pointer;
  }
}

.check-icon {
  width: 25px;
  height: 25px;

  path {
    fill: $friendly-green;
  }
}

.mobile-avatar-cropper {
  width: 90%;
}

.licenses-modal {
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 3rem;
  }
}
