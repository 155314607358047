@import 'styles/colors';
@import 'styles/variables';

.warn-text {
  color: $friendly-red;
}

.wrapper,
.info-section {
  padding: 0 $content-padding;
}

.wrapper {
  padding-top: 120px;
  max-width: 1400px;
  margin: auto;

  .breadcrumbs {
    display: flex;
    align-items: flex-start;

    .crumb {
      color: $dark-gray;

      &.link {
        cursor: pointer;
        text-decoration: underline;
        transform: opacity 0.2s ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .chevron {
      width: 20px;
      height: 20px;
      transform: rotate(-90deg) scale(0.7);

      path {
        fill: $dark-gray;
      }
    }
  }

  .heading-wrapper {
    display: flex;
    align-items: center;

    .avatar {
      width: 57px;
      height: 55px;
      border-radius: 10px;
    }

    .btn-add {
      svg {
        path {
          transition: fill 0.3s ease-in-out;
        }
      }

      &:hover {
        svg {
          path {
            fill: $friendly-green !important;
          }
        }
      }

      .add-label {
        display: flex;
        align-items: center;
      }
    }
  }
}

.classrooms-section {
  .classrooms-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .classrooms-table {
    width: 100%;
  }

  .table-dropdown {
    background: unset;

    svg {
      path {
        fill: $sesame-black;
      }
    }
  }
}

.info-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .creds-wrapper {
    min-width: 60%;

    .title-select {
      width: 110px;
    }

    .sub-wrapper {
      display: flex;
      flex-wrap: wrap;

      .input {
        max-width: 300px;
      }
    }
  }

  .revoke-wrapper {
    display: flex;
    min-width: 40%;
  }

  .disable-wrapper {
    width: 860px;
    border: 1px solid $alto;
    display: flex;
    justify-content: space-between;

    .sub-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.manage-modal {
  height: 740px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    width: 100vw;
    min-height: 100vh;
    border-radius: 0;
  }

  .modal-body {
    height: 100%;
    overflow: auto;

    .label {
      color: $dark-gray;
    }

    .center-text {
      text-align: center;
    }

    .search {
      width: 100%;
      height: 50px;
      border-radius: 2px;

      transition: all 0.2s ease;

      &:focus {
        box-shadow: 0px 0px 0px 5px rgba(0, 177, 64, 0.2);
        border: 1px solid $friendly-green;
      }
    }

    .classrooms-wrapper {
      position: relative;
      width: 100%;
      list-style: none;
      padding: 0;

      .item {
        position: relative;
        will-change: transform, height, opacity;
        width: 100%;

        display: flex;
        align-items: center;

        .image-wrapper {
          width: 44px;
          height: 44px;
          border-radius: 10px;

          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          .placeholder {
            width: 44px;
            height: 44px;
            border-radius: 10px;
          }
        }

        .image {
          height: 100%;
        }

        .texts {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 15px;

          * {
            max-width: 350px;
            white-space: nowrap;

            @include mobile {
              max-width: 170px;
            }
          }
        }

        .action-text {
          color: $friendly-green;
          cursor: pointer;
          margin-left: auto;
          margin-right: 30px;
        }

        &:last-of-type {
          padding-bottom: 20px;
        }
      }
    }

    .container {
      opacity: 0;
      height: inherit;
      animation-name: fadeIn;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;

      .not-assigned-wrapper {
        width: 100%;
        height: 40%;
        overflow: auto;
      }

      .assigned-wrapper {
        width: calc(100% + 60px);
        height: 50%;
        background-color: $concrete;
        transform: translateX(-30px);
        border-top: 1px solid $alto;
        border-bottom: 1px solid $alto;
        overflow: auto;
      }
    }
  }

  .save-button {
    @include mobile {
      width: 100%;
    }
  }

  .invite-wrapper {
    .add-person-wrapper {
      display: flex;
      align-items: center;
      width: fit-content;

      cursor: pointer;

      transition: opacity 0.1s ease;

      &:hover {
        opacity: 0.9;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 42px;

        background-color: $friendly-green-03;
        border-radius: 10px;
      }

      .invite-member {
        color: $friendly-green;
      }
    }

    .email-row {
      display: flex;
      align-items: center;
      border-radius: 10px;
      background-color: $concrete;
      padding: 12px 10px;
      margin: 10px 0;
    }
  }

  &-footer {
    @include mobile {
      box-shadow: 0px -8px 15px 0px rgba(0, 0, 0, 0.05);
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.remove-modal {
  width: 490px;

  .cancel-button {
    &:hover {
      border-color: $sesame-black !important;
      background-color: $sesame-black !important;
      color: $secondary-white !important;
    }
  }
}
