@import 'styles/colors';
@import 'styles/variables';

$box-size: 261px;
$mobile-box-size: 145px;

.box-wrapper {
  width: $box-size;
  height: $box-size;
  border-radius: 5px;
  background-color: $light-gray;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  position: relative;

  cursor: pointer;
  transition: 0.2s ease-in-out;

  @include mobile {
    width: $mobile-box-size;
    height: $mobile-box-size;
  }

  &:hover {
    opacity: 0.7;

    @include mobile {
      opacity: 1;
    }
  }

  &--active {
    box-shadow: 0 0 0 2px $friendly-green;
  }

  &--icon {
    position: absolute;
    top: 12px;
    left: 12px;

    transition: 0.2s ease-in-out;

    @include mobile {
      top: 5px;
      left: 5px;
    }
  }

  .current-image {
    position: absolute;
    padding: 2px;

    top: 12px;
    right: 12px;

    color: #1a1a1a;
    border: 1px solid #1a1a1a;
    border-radius: 5px;

    @include mobile {
      font-size: 12px;
      top: 10px;
      right: 8px;
    }
  }
}

.box {
  margin: 20px;

  @include mobile {
    margin: 30px 0 0 0;
  }
}
