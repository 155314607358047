@import 'styles/colors';
@import 'styles/variables';

@mixin mobileNavitemActiveState($section) {
  &--active {
    background-image: url('../../icons/navigation/#{$section}-active.svg');
    .mobile-text {
      color: $friendly-green;
      font-weight: bolder;
    }
  }
}

.license {
  height: 40px;
  width: 100%;
  min-width: 768px;
  background: #000;
  background-color: $joyeful-yellow;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    color: #000;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  &.license-mobile {
    position: absolute;

    transform: translateY(-100%);
  }
}

.underline-wrapper {
  position: relative;
  padding: 0 4px 3px 3px;
  display: inline-flex;
  overflow: hidden;

  .underline {
    height: 1px;
    background-color: $friendly-green;
    position: absolute;
    left: -1px;
    bottom: 0;
  }

  &.admin {
    .underline {
      background-color: $friendly-red;
    }
  }
}

.wrapper {
  display: flex;
  position: fixed;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  overflow: hidden;
  background-color: #fff;
  z-index: 20;
  min-width: 768px;

  @media screen and (max-width: 765px) {
    position: relative;
  }

  &--active {
    z-index: 30;
  }

  .menu {
    width: 100%;
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px;

    .logo-wrapper {
      width: 300px;
      position: relative;

      @media (max-width: 1000px) {
        width: 200px;
      }

      &.is-ie {
        height: 100%;
      }

      .logo {
        position: absolute;
        transform: translateY(-50%);

        @media (max-width: 1000px) {
          width: 200px;
        }

        &.is-ie {
          transform: none;
        }
      }
    }

    .menu-items {
      color: $dark-gray;
      flex: 1 0;
      display: flex;
      justify-content: center;
      height: 35px;
      align-items: center;

      .menu-item,
      .vert-separator {
        margin-right: 10%;
      }
      .menu-item:last-child {
        margin-right: 0;
      }
    }
  }

  .collapsed-content {
    padding: 0 60px 30px;
    background: #fff;
  }

  .profile {
    display: flex;
    align-items: center;
    cursor: pointer;

    .name-wrapper {
      color: $dark-gray;
      text-align: right;
      padding-right: 15px;
    }
  }

  .profile-section {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .profile-link {
      margin-bottom: 10px;
    }
    .profile-link,
    .sign-out {
      transition: color 0.15s ease-in-out;
    }

    .profile-link.switch-program {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: $friendly-green;
      }
      .chevron-right {
        transform: rotate(-90deg);
      }
    }

    .profile-link:hover {
      color: $dark-gray;
    }

    .sign-out:hover {
      color: $friendly-red;
    }
  }
}

.classrooms {
  height: 230px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  align-content: flex-start;

  &.centered {
    align-content: center;
  }

  .classroom-wrapper {
    display: flex;
    width: 278px;
    margin-bottom: 15px;
    margin-right: 15px;

    &:nth-child(3n) {
      margin-bottom: 0;
    }

    .image-wrapper {
      height: 56px;
      width: 56px;
      background: gray;
      display: flex;
      flex-shrink: 0;
      overflow: hidden;
      border-radius: 10px;
      flex-shrink: 0;
      margin-right: 20px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      .image {
        height: 100%;
      }
    }

    .description {
      width: 197px;
    }

    .text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .sub-text {
      color: $dark-gray;
    }
  }
}

.text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mobile-wrapper {
  position: fixed;
  bottom: 0;
  margin-top: -50px;
  height: $mobile-nav-height;
  width: 100%;
  background-color: $secondary-white;
  box-shadow: 0px -8px 15px rgba(0, 0, 0, 0.05);

  z-index: 30;

  &.hidden {
    display: none;
  }

  .mobile-links {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 15px auto;

    .mobile-item {
      height: 44px;
      margin-top: auto;
      color: $dark-gray;
      font-size: 10px;
      text-decoration: none;
      text-align: center;

      .mobile-text {
        margin-top: 26px;
      }
    }
  }

  .mobile {
    &--classrooms {
      background: url('../../icons/navigation/classrooms.svg') no-repeat 50% 0;

      @include mobileNavitemActiveState('classrooms');
    }

    &--library {
      background: url('../../icons/navigation/favorites.svg') no-repeat 50% 0;

      @include mobileNavitemActiveState('favorites');
    }

    &--profile {
      background: url('../../icons/navigation/profile.svg') no-repeat 50% 0;

      @include mobileNavitemActiveState('profile');
    }

    &--admin {
      background: url('../../icons/navigation/admin.svg') no-repeat 50% 0;

      @include mobileNavitemActiveState('admin');
    }
  }
}

.vert-separator {
  height: 100%;
  width: 1px;
  background-color: $silver-chalice;
}

.program-switcher-collapse {
  position: relative;

  .switcher-wrapper {
    top: -60px;
    background: white;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .heading {
      display: flex;
      align-items: center;
      width: 280px;
      color: $dark-gray;

      .button {
        margin-right: 50px;
      }
    }

    .programs-list {
      display: flex;
      flex-direction: column;
      width: 280px;
      position: relative;
      padding: 17px 0;

      .program-wrapper {
        display: flex;
        align-items: center;

        .avatar {
          height: 56px;
          width: 56px;
          margin-right: 20px;
        }
        margin-bottom: 15px;
        cursor: pointer;
      }

      .check {
        color: $friendly-green;
        height: 25px;
        width: 25px;
        margin-left: 20px;
      }
    }
  }
}
