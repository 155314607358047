@import 'styles/colors';
@import 'styles/variables';

.warn-text {
  color: $friendly-red;
}

.wrapper,
.info-section,
.people-section,
.schools-section {
  padding: 0 $content-padding;

  .table {
    width: 100%;
  }

  .table-dropdown {
    background-color: transparent;

    svg {
      path {
        fill: $sesame-black;
      }
    }
  }
}

.info-section,
.people-section,
.schools-section {
  padding-bottom: 50px;
}

.wrapper {
  padding-top: 120px;
  max-width: 1400px;
  margin: auto;

  .breadcrumbs {
    display: flex;
    align-items: flex-start;

    .crumb {
      color: $dark-gray;

      &.link {
        cursor: pointer;
        text-decoration: underline;
        transform: opacity 0.2s ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .chevron {
      width: 20px;
      height: 20px;
      transform: rotate(-90deg) scale(0.7);

      path {
        fill: $dark-gray;
      }
    }
  }

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__left {
      display: flex;

      .school-image {
        width: 56px;
        height: 56px;
        border-radius: 10px;
        background-color: $friendly-red;
        margin-right: 17px;
        text-align: center;
        line-height: 56px;
        color: $secondary-white;

        &.no-bg {
          background-color: unset;
        }
      }

      .information {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }
}

.btn-add {
  svg {
    path {
      transition: fill 0.3s ease-in-out;
    }
  }

  &:hover {
    svg {
      path {
        fill: $friendly-green !important;
      }
    }
  }

  .add-label {
    display: flex;
    align-items: center;
  }
}

.info-section {
  padding-top: 30px;
  margin-bottom: 100px;

  .general-info-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;

    .info-form {
      width: 410px;
      margin-right: 150px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .image-wrapper {
      margin-right: 130px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .image-title {
        font-size: 14px;
      }

      .program-image {
        width: 170px;
        height: 170px;
        border-radius: 5px;
        background-color: $friendly-red;
        color: $secondary-white;
        font-size: 32px;
        text-align: center;
        line-height: 170px;
        font-family: VAG;

        &.no-bg {
          background-color: unset;
        }
      }
    }

    .revoke-wrapper {
      width: 100%;
      position: absolute;
      bottom: -40px;
      transform: translateY(100%);

      background-color: $concrete;
      border-radius: 5px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .texts {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }

  .license-wrapper {
    max-width: 865px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 1px solid $alto;
    border-radius: 5px;

    .license-header {
      padding: 0;

      * {
        color: $sesame-black;
      }
    }

    .table-row {
      padding: 0;
      position: relative;

      &.visible {
        overflow: visible;
      }

      &.license-row {
        p {
          font-family: VAG;
          font-size: 18px;
        }
      }
    }

    .actions-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .numeric-input-wrapper {
      position: absolute;
      left: 0;
      transform: translateX(-40%);
    }

    .contact-sales {
      &:link {
        color: $friendly-green;
      }
    }
  }

  .program-users-wrapper {
    min-width: 860px;
    display: flex;
    flex-direction: column;

    .heading-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .admins-wrapper {
      max-width: 900px;
      overflow-x: auto;
      display: flex;
      margin-left: -15px;

      @include forcedScrollbar;
    }
  }
}

.schools-section,
.people-section {
  .heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: Source Sans Pro;
    }
  }

  .school-name-col,
  .user-name-col {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-gray;

    p {
      max-width: 200px;
    }
  }

  .school-name-col {
    .school-text {
      max-width: 210px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .buttons-col {
    justify-content: flex-end;
  }
}

.add-content {
  height: 450px;
}

.schools-section {
  .school-col {
    display: flex;
    align-items: center;

    &__image {
      height: 44px;
      width: 44px;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: flex-end;
      overflow: hidden;
      border-radius: 10px;

      .image-wrapper {
        width: 44px;
        height: 44px;

        .image {
          height: 100%;
          width: auto;
        }
      }

      .placeholder {
        width: 44px;
        height: 44px;
        border-radius: 10px;
        color: $secondary-white;
        text-align: center;
        line-height: 44px;
        width: 100%;
        background-color: $friendly-red;

        // &.classroom {
        //   background-color: transparent;
        // }
      }
    }
  }
}

.people-section {
  .user-col {
    display: flex;
    align-items: center;

    &__image {
      .img-icon-wrapper {
        width: fit-content;
        height: fit-content;
        position: relative;

        .image {
          width: 45px;
          height: 45px;
          border-radius: 10px;
        }

        .badge-people {
          position: absolute;
          right: 0;
          bottom: 0;
          transform: translate(50%, 50%);

          path {
            fill: $secondary-white !important;
          }
        }
      }
    }
  }
}

.assoc-cart-container {
  position: relative;
  height: 100%;

  .round-cart {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 36px;
    color: $secondary-white;
    border: 2px solid $secondary-white;
    background-repeat: no-repeat;
    background-size: cover;

    &.schools {
      background-color: $thoughtful-teal;
    }

    &.classrooms {
      background-color: $orange;
    }

    &.last {
      background-color: $dark-gray;
    }
  }
}

.add-school-modal {
  .header-wrapper {
    display: flex;
    flex-direction: column;

    .text {
      opacity: 0.7;
    }
  }

  .form-wrapper {
    .modal-body {
      min-height: 75vh;
      max-height: 75vh;
      overflow: auto;
    }

    .licenses-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .texts {
        display: flex;
        flex-direction: column;
      }
    }

    .error {
      color: $friendly-red;
      font-size: 12px;
    }

    hr {
      border: none;
      border-top: 1px solid $alto;
    }
  }

  @include mobile {
    width: 100%;
    min-height: 100%;
    border-radius: 0;
    position: relative;

    .form-wrapper {
      .modal-body {
        height: 100%;
        max-height: 75vh;
      }

      .button-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .submit-button {
          width: 100%;
        }
      }
    }
  }
}

.program-invite-modal {
  .email-container {
    min-height: 140px;
  }

  @include mobile {
    width: 100%;
    min-height: 100%;
    border-radius: 0;
    position: relative;

    form {
      padding: 55px 30px;
      height: calc(100% - 72px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .submit-button {
      width: 85%;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
