@import 'styles/variables';
@import 'styles/colors';

.modal {
  max-width: 500px;

  @include mobile {
    max-width: 335px;
    border-radius: 5px;
  }
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mobile {
    padding: 10px 20px;
  }

  .image-cropper {
    img {
      width: 100%;
    }
  }

  .loader-wrapper {
    width: 100%;
    min-height: 400px;

    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      min-height: 200px;
    }
  }

  &--buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;

    @include mobile {
      margin: 30px 0 15px auto;
    }

    &--first {
      margin-right: 15px;
    }

    .file-input {
      display: none;
    }
  }

  .submit-button {
    width: 118px;
  }

  .unsupported-wrapper {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .text {
      color: $dark-gray;
    }
  }
}
