@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  max-width: 1400px;
  margin: auto;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    margin: auto;
  }

  .switcher-wrapper {
    margin-top: 70px;
    width: 413px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .search {
      width: 100%;
    }

    .program-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 18px 30px 18px 18px;
      cursor: pointer;
      border-top: 1px solid $alto;

      &:last-child {
        border-bottom: 1px solid $alto;
      }

      .avatar {
        margin-right: 10px;
      }

      .name {
        font-size: 20px;
      }

      .chevron {
        margin-left: auto;
        height: 30px;
        width: 30px;
        transform: rotate(180deg);
      }
    }

    @include mobile {
      max-width: 420px;
      width: 90%;
    }
  }
}

.program-avatar {
  border-radius: 10px;
  background-color: $friendly-red;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &.is-avatar {
    background-color: unset;
  }
}
