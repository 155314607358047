@import 'styles/colors';

.modal {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;

  .modal-container {
    position: relative;
  }

  &--header {
    display: flex;
    justify-content: space-between;
  }

  .wrapper {
    display: flex;
    flex-flow: row wrap;
    max-width: 1440px;
    margin: auto;

    @media (max-width: 1435px) {
      max-width: 960px;
    }
  }

  .action-buttons {
    margin-left: auto;
    padding-right: 25px;

    &--button {
      height: 32px;
      margin: 0 5px;
      font-weight: 500;

      &--black {
        border-color: $sesame-black;
        background-color: $sesame-black;

        &:hover {
          color: $sesame-black;
          background-color: $secondary-white;
        }
      }
    }
  }
}
