@import 'styles/colors';
@import 'styles/variables';

.modal {
  @include mobile {
    max-width: 335px;
    border-radius: 5px;
  }

  &--header {
    .content-left {
      display: flex;
      align-items: center;

      &--text {
        @include mobile {
          font-size: 17px;
        }
      }

      &--icon {
        @include mobile {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  &--body {
    width: 600px;
    height: 415px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mobile {
      width: auto;
      padding: 40px 20px !important;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;

      @include mobile {
        margin-left: 0;
        margin-bottom: -15px;
        display: flex;
        justify-content: space-evenly;
      }

      &--accept {
        &:hover {
          background-color: $friendly-red;
          color: $secondary-white;
          border-color: $friendly-red;
        }

        @include mobile {
          width: 150px;
          padding: 0 5px;
        }
      }

      &--cancel {
        width: 110px;
        margin-left: 10px;

        @include mobile {
          width: 130px;
        }
      }
    }
  }
}
