@import 'styles/colors';
@import 'styles/variables';

@mixin vertical-line($margin-top, $right) {
  &::after {
    content: ('');
    position: absolute;
    top: 0;
    right: #{$right}px;
    width: 1px;
    height: 30px;
    background-color: $alto;
    margin-top: #{$margin-top}px;
  }
}

.rdw-editor-wrapper {
  width: 100%;
  height: 350px;
  margin-bottom: 50px;

  @include mobile {
    width: 100%;
  }
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.rdw-editor-toolbar {
  height: 48px;
  margin: 0 !important;
  border: 1px solid $alto;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}

.rdw-dropdown-wrapper {
  border: none;
  position: relative;

  .rdw-dropdown-carettoopen {
    visibility: hidden;

    &::before {
      content: ('');
      position: absolute;
      background: url('../../../icons/chevron.svg');
      background-repeat: no-repeat;
      background-size: contain;
      top: -9px;
      width: 18px;
      height: 18px;

      visibility: visible;
    }
  }

  .rdw-dropdown-carettoclose {
    visibility: hidden;

    &::before {
      content: ('');
      position: absolute;
      background: url('../../../icons/chevron.svg');
      background-repeat: no-repeat;
      background-size: contain;
      top: -4px;
      width: 18px;
      height: 18px;
      transform: rotateZ(180deg);

      visibility: visible;
    }
  }

  @include vertical-line(0, -15);
}

.rdw-block-dropdown {
  @include mobile {
    width: 70px;
  }
}

.rdw-option-wrapper {
  border: none;

  img {
    width: 18px;
  }
}

.rdw-inline-wrapper {
  margin-left: 20px;
  position: relative;

  @include mobile {
    margin-left: 10px;
  }

  @include vertical-line(3, -10);
}

.rdw-link-wrapper {
  margin-left: 20px;
  position: relative;

  @include vertical-line(3, 0);

  @include mobile {
    margin-left: 10px;
  }

  @media (max-width: 320px) {
    display: none;
  }
}

.rdw-list-wrapper {
  margin-left: 20px;

  @include mobile {
    margin-left: 10px;
  }
}

.rdw-dropdown-selectedtext {
  font-size: 14px;
}

.rdw-list-dropdown {
  height: 100%;
  padding-bottom: 6px;
  margin-left: 10px;
  width: 35px;
  z-index: 1;

  img {
    width: 18px;
  }

  &::after {
    content: ('');
    width: 0;
  }
}
