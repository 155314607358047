@for $i from 1 through 16 {
  $class-prefix: $i * 5;
  $value: #{$class-prefix}px;

  .m-#{$class-prefix} {
    margin: $value;
  }

  .mr-#{$class-prefix} {
    margin-right: $value;
  }

  .ml-#{$class-prefix} {
    margin-left: $value;
  }

  .mt-#{$class-prefix} {
    margin-top: $value;
  }

  .mb-#{$class-prefix} {
    margin-bottom: $value;
  }

  .mh-#{$class-prefix} {
    margin-left: $value;
    margin-right: $value;
  }

  .mv-#{$class-prefix} {
    margin-top: $value;
    margin-bottom: $value;
  }

  .p-#{$class-prefix} {
    padding: $value;
  }

  .pr-#{$class-prefix} {
    padding-right: $value;
  }

  .pl-#{$class-prefix} {
    padding-left: $value;
  }

  .pt-#{$class-prefix} {
    padding-top: $value;
  }

  .pb-#{$class-prefix} {
    padding-bottom: $value;
  }

  .ph-#{$class-prefix} {
    padding-left: $value;
    padding-right: $value;
  }

  .pv-#{$class-prefix} {
    padding-top: $value;
    padding-bottom: $value;
  }
}
