@import 'styles/colors';
@import 'styles/variables';

$expanded-height: 60px;

.content-wrapper {
  // width: 100vw;
  // height: 100%;
}

.tab-content {
  // padding-bottom: 62px;
}

.page-tab-nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 $content-padding;
  width: 100%;

  &--border {
    border-bottom: 1px solid #dddddd;
  }

  &--sticky {
    position: sticky;
    top: 40px;
    left: 0;
    z-index: 10;
    background-color: $secondary-white;

    &.is-ie {
      position: fixed;
    }
  }

  &--expanded {
    width: 100%;
    height: $expanded-height;
    position: fixed;
    top: $navbar-browser-height;
    z-index: 20;

    background-color: $secondary-white;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);

    animation: slideMeIn;
    animation-direction: normal;
    animation-duration: 0.4s;
  }

  &--middle {
    align-items: center;
  }

  .nav-items {
    margin-top: 30px;
    display: flex;

    .nav-item {
      padding: 15px;
      color: $dark-gray;
      position: relative;

      &.active-tab {
        color: black;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &--expanded {
      margin-top: 10px;
    }
  }

  .horizontal-line {
    margin: 0 3px;
    height: 1px;
    width: 100%;
    border: solid 1px transparent;
    border-top-color: $light-gray;

    hr {
      position: absolute;
      height: 4px;
      background-color: $friendly-green;
      border: none;
      margin: 0;
      transform: translateY(-50%);
      transition: 0.2s ease-in-out;
    }

    &--hidden {
      border: 0;
    }
  }
}

.content-right {
  // mainly for admin page, to be adjusted
  position: absolute;
}

@keyframes slideMeIn {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: $expanded-height;
  }
}
