
.anim-wrapper {
 
}

.easter-wrapper {
  z-index: 100;
  position: fixed;
  background: transparent;
  background: radial-gradient(ellipse at left bottom, rgba(157,29,150,1) 0%, rgba(0,0,0,0) 100%);
  width: 100%;
  background-size: 150%;
  animation: fadeIn 2.5s ease;
  opacity: 0;
}

.fairy {
  position: absolute;
  animation: flying 2.5s ease;
  height: 30%;
  width: auto;
  left: -100%;
  top: -100%;
}

@keyframes flying {
  25% {
    top: 100vh;
    left: -30vw;
  }

  50% {
    top: 40vh;
    left: 40vw;
  }

  60% {
    top: 40vh;
    left: 40vw;
  }

  100% {
    top: -30vh;
    left: 100vw;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
