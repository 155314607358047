@import 'styles/colors';

.dropdown {
  width: 100%;
  position: relative;
  
  .powered-by-google {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 11px;
    color: $dark-gray;
  }

  .dropdown-container {
    position: relative;

    .dropdown-body {
      position: absolute;
      left: 0;
      top: 0;
      max-height: 200px;
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
      overflow-y: auto;

      .dropdown-item-wrap {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 50px;
        background-color: white;
        padding: 5px 10px;
        border-bottom: 1px solid $light-gray;
        cursor: pointer;
        z-index: 1;

        &.active {
          background-color: $light-gray;
        }

        .dropdown-item {
          width: 100%;
        }
      }
    }
  }
}