@import 'styles/variables';

.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 60px;

  @media (max-width: 1050px) {
    padding: 40px 62px !important;
  }

  @media (max-width: 576px) {
    padding: 15px 0 0 0 !important;
  }
}

.card-wrapper {
  width: 100%;
  margin-right: 20px;
  margin-bottom: 40px;
  flex: 1 0 620px;
  max-width: 620px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  @media (max-width: 1200px) {
    max-width: 47%;
    flex: unset;
  }

  @include mobile {
    max-width: unset;
    flex: unset;
    margin-right: 0;
  }
}

@media screen and (min-width: 1400px) {
  .card-wrapper:nth-child(2n + 2) {
    margin-left: 20px;
    margin-right: 0;
  }
}
