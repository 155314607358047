// if color doesn`t have a name use https://chir.ag/projects/name-that-color/

$sesame-black: #151515;
$dark-gray: #54585a;
$secondary-white: #fff;
$secondary-white-08: rgba(255, 255, 255, 0.8);
$friendly-green: hsl(142, 100%, 35%);
$light-gray: #e3e3e3;
$friendly-red: #e03e52;
$error-red: #ea0029;
$friendly-red-007: rgba(224, 62, 82, 0.07);
$friendly-green: #00b140;
$friendly-green-03: adjust-color(
  $color: $friendly-green,
  $alpha: -0.7,
);
$wild-sand: #f5f5f5;
$friendly-red: #e03e52;
$joyeful-yellow: #ffd100;
$orange: #ff5100;
$thoughtful-teal: #00c4b3; // Pre-K4
$purple: #9d1d96; // Pre-K3
$blue: #0082ca; // Mixed classrooms
$concrete: #f2f2f2;
$alto: #dddddd;
$silver-chalice: #ababab;
$alabaster: #f7f7f7;
$charcoal: #454545;
$snow: #fafafa;
$silver: #c4c4c4;

@function color-opacity($given-color, $reduce-val) {
  $col: adjust-color(
    $color: $given-color,
    $alpha: $reduce-val,
  );
  @return $col;
}
