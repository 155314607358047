@import 'styles/colors';

.heading-wrapper {
  width: 100%;
  height: 85px;
}

.main-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .heading-wrapper {
    .heading-text {
      text-align: center;
      margin-top: 48px;
    }
  }

  .user-wrapper {
    width: 100%;
    height: 80px;
    padding: 15px 30px;

    display: flex;
    align-items: center;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 5px;
    }

    &--texts {
      font-family: Source Sans Pro;
    }
  }

  .actions-wrapper {
    width: 100%;
    height: 100%;

    .clickable-section {
      height: 100%;
      margin-left: 65px;

      &.wide {
        margin-left: 30px;
      }

      &--item {
        width: 100%;
        height: 40px;

        display: flex;
        align-items: center;

        position: relative;

        &--icon {
          position: absolute;
          top: 50%;
          left: 0;

          transform: translate(-150%, -50%);
        }

        &--link {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  .terms-wrapper {
    width: 100%;
    height: 123px;

    position: relative;

    .terms-section {
      width: 100%;
      height: 100%;
      padding-left: 30px;

      &--item {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }

  .logout-wrapper {
    width: 100%;
    height: 40px;

    position: relative;

    .logout-section {
      width: 100%;
      height: 100%;
      padding-left: 30px;

      display: flex;
      align-items: center;
    }
  }

  .bottom-section {
    width: 100%;
    height: 120px;
    background-color: $concrete;
    border-top: 1px solid $alto;

    display: flex;
    justify-content: center;

    &--text {
      color: $silver-chalice;
      margin-top: 80px;
    }
  }
}

.animation-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.empty-container {
  width: 100%;
  height: 40px;
  border-top: 1px solid $alto;
  border-bottom: 1px solid $alto;
  background-color: $concrete;
}

.chevron-right {
  width: 25px;
  height: 25px;
  transform: rotateZ(180deg);
  margin-right: 20px;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: $alto;
}

.personal-container {
  width: 100%;
  height: fit-content;
  background-color: $secondary-white;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .avatar-wrapper {
    position: relative;
    margin-bottom: 25px;

    .camera {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .avatar-big {
      width: 170px;
      height: 170px;
      border-radius: 5px;
    }

    .admin-badge {
      position: absolute;
      top: 0;
      left: 190px;
    }
  }

  .title-select {
    width: 115px;
  }
}
.submit-button {
  width: 100%;
  margin-top: 20px;
}

.classrooms-wrapper {
  padding-top: 15px;
  margin-bottom: 150px;
  // min-height: 100%;

  .classroom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;

    .image-wrapper {
      width: 56px;
      height: 56px;
      border-radius: 10px;
      margin-right: 10px;
      display: flex;
      align-items: center;

      &--image {
        width: 56px;
      }
    }
    &--texts {
      max-width: 150px;
      margin-right: auto;

      .classroom-name {
        white-space: nowrap;
      }
    }
  }

  .manage-button {
    width: 65px;
    height: 25px;
    margin-bottom: auto;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.add-classroom {
  position: fixed;
  right: 30px;
  bottom: 110px;
  z-index: 20;

  cursor: pointer;
}

.mobile-avatar-cropper {
  max-width: 335px;
}

.switcher-container {
  .search-wrapper {
    width: 100%;
  }

  .search-input {
    width: 100%;

    &:focus {
      width: 100%;
    }
  }

  .hr {
    width: 100%;
    height: 1px;
    background-color: $alto;
  }

  .program-row {
    display: flex;
    align-items: center;
    padding: 15px 0;

    .avatar-wrapper,
    .placeholder-wrapper {
      width: 44px;
      height: 44px;
      border-radius: 10px;
    }

    .placeholder-wrapper {
      line-height: 44px;
      text-align: center;
      background-color: $friendly-red;
      color: $secondary-white;
      font-size: 15px;
    }

    .texts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .check-icon {
      width: 20px;
      height: 20px;
    }
  }
}
