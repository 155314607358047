@import 'styles/colors';
@import 'styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(30, 38, 62, 0.7);
  padding: 0 0 100px 0;

  z-index: 40;

  .main-wrapper {
    max-width: 1000px;
    border-radius: 5px;
    padding: 60px 60px 30px 60px;
    background-color: $secondary-white;
    opacity: 1;
    height: fit-content;
    margin-top: 100px;

    position: relative;

    @include mobile {
      max-width: 335px;
      padding: 60px 20px 20px 20px;
    }

    &--icon {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 40px;
      height: 40px;

      &:hover {
        cursor: pointer;
      }
    }

    .body-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--buttons {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;

        &--first {
          margin-right: 15px;
        }

        .file-input {
          display: none;
        }
      }
    }
  }
}
