@import 'styles/variables';

.wrapper {
  padding: 15px 0;
  width: 385px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 85%;
  }

  .log-in-button {
    width: 123px;

    @include mobile {
      width: 100%;
    }
  }

  .box-link {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.logo {
  margin: auto;
  margin-bottom: 37px;
  padding: 30px 0;

  @include mobile {
    padding: 30px 0 0 0;
  }
}
