@import 'styles/colors';
@import 'styles/variables';

.table-container {
  overflow-x: auto;

  @include forcedScrollbar;
}

.table {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  overflow: hidden;
  min-width: 595px;
  color: $sesame-black;

  &.isSmall {
    .table__header {
      padding: 5px 17px;
    }
    .table__header .table__cell {
      min-height: 20px;
    }

    .table__row .table__cell {
      padding: 12px 5px;
      min-height: 30px;
    }
  }

  .table__body {
    background: white;
    display: flex;
    flex-direction: column;

    .table__row-wrapper:first-child {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-top: 0;
      &::before {
        opacity: 0;
      }
    }
  }

  .table__cell {
    display: flex;
    width: 1px;
    flex: 1 0 120px;
    min-height: 30px;
    overflow-x: auto;
  }

  .table__header {
    display: flex;
    font-size: 15px;
    border-bottom: 1px solid $light-gray;
    padding: 10px 17px;
    flex: 1;

    // IE stuff
    min-height: 40px;

    .table__cell {
      color: $dark-gray;

      .cell-wrapper {
        display: flex;

        &.sortable {
          cursor: pointer;
        }

        .sort-icon {
          width: 15px;
          height: 15px;
          color: $dark-gray;
          margin: 3px 0 0 5px;

          transition: transform 0.2s ease;
          transform: rotate(90deg);

          &.ascending {
            transform: rotate(270deg);
          }
        }
      }
    }
  }

  .table__row-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: all 0.3s ease;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #fff;
      top: -3px;
      transition: all 0.3s ease;
      opacity: 0;
    }

    &.bordered {
      border-color: $light-gray;
      margin-bottom: 10px;

      &::before {
        opacity: 1;
      }
    }
  }

  .table__row {
    display: flex;

    &:last-child {
      .table__cell {
        border-bottom: none;
      }
    }

    .table__cell {
      padding: 18px 10px 18px;
      min-height: 79px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;

      .table-text {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.bordered {
        border-bottom: 1px solid $light-gray;
      }
    }

    .table__cell:last-child {
      border-right: none;
    }
  }

  .table__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-weight: bold;
    font-size: 16px;
    color: gray;
  }
}
