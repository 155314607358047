@import 'styles/colors';

.row-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  .image-wrapper {
    width: 56px;
    height: 56px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      height: 100%;
    }

    .image-placeholder {
      width: 100%;
      height: 100%;
      background-color: $friendly-red;
      line-height: 56px;
      text-align: center;
      font-size: 17px;
      color: white;
    }
  }

  .menu-wrapper {
    margin-left: auto;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .badge {
    position: absolute;
    top: 40px;
    left: 40px;
  }
}
