@import 'styles/variables';
@import 'styles/colors';

.wrapper {
  overflow: hidden;
  height: 100%;
  width: 100vw;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 0;

  &--main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    flex-shrink: 0;

    .heading {
      padding-top: 30px;
      text-align: center;
      max-width: 70vw;
    }

    .breadcrumbs {
      font-family: Source Sans Pro;
      color: $dark-gray;
      text-align: center;
    }
  }

  &--chevron {
    position: absolute;
    top: 43px;
    left: 15px;
  }

  &--dots {
    position: absolute;
    top: 43px;
    right: 30px;
  }
}


.menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &--subsection {
    margin: 10px 0;
    display: inline-flex;
    align-items: center;

    cursor: pointer;
  }
}

.resources-nav {
  position: absolute;
  top: 0;
  right: -300px;
  height: 100%;
  width: 100%;
  z-index: 11;
  display: flex;
  pointer-events: none;
  touch-action: none;
  
  
  .button-wrapper {
    width: 20%;
    height: 100%;
    pointer-events: none;
    touch-action: none;
    transition: all .3s ease;
    pointer-events: none;
    touch-action: none;

    &.isExpandedRes {
      background: rgba(0,0,0,0.2);
    }
    
    .button {
      display: flex;
      height: 40px;
      width: 60px;
      background: white;
      color: black;
      justify-content: center;
      align-items: center;
      padding: 0 13px;
      margin: 115px 10px 0 auto;
      border-radius: 40px;
      cursor: pointer;
      transition: all .3s ease;
      box-shadow: 0px 1px 12px rgba(0,0,0,0.3);
      pointer-events: all;
      touch-action: auto;
      
      &.isExpandedRes {
        padding: 0;
        width: 40px;
        margin: auto;
        margin-top: 30px;
        justify-content: center;

        &:hover {
          color: white;
          background-color: black;
        }
      }
    }
  }

  &.isFullscreen {
    // margin-top: 70px;


    .button {
      background: rgba(0,0,0,0.4);
      border: 1px solid white;
      color: white;

      &.isExpandedRes {

        &:hover {
          color: black;
          background-color: white;
        }
      }
    }
  }

  .content-wrapper {
    padding: 20px 40px;
    background-color: #fff;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: hidden;
    width: 300px;
    pointer-events: all;
    touch-action: auto;


    &.isFullscreen {
      background-color: black;
      color: white;
    }
  }
}

.resource {
  display: block;
  pointer-events: all;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  padding-left: 25px;


  margin: 10px 0;
  
  .link-icon {
    position: absolute;
    right: 0;
    color: $friendly-green;
    transition: all .3s ease;
  }
  
  &.isFullscreen {
    &:hover .link-icon {
      color: $friendly-green;
    }

    .link-icon {
      color: white;
    }
  }
}

// .content {
//   position: relative;
// }

.bottom-menu {
  box-shadow: 0 -3px 45px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  margin-top: -50px;
  // padding: 0 30px 40px 30px;
  background-color: #fff;
  z-index: 10;
  overflow-y: auto;
  color: #fff;
  position: fixed;
  background: $friendly-green;
  left: 0;
  bottom: -90%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: background-color .3s ease;

  .divider {
    width: calc(100% - 46px);
    height: 1px;
    background: #ddd;
    margin: auto;
    transition: all .3s ease;
  }

  &.isFullOpen {
    z-index: 12;
    .divider {
      width: 100%;
    }

    .content-wrapper{
      max-height: 100%;
    }
  }

  &.isExpanded {
    background: #fff;
    color: black;
}

  .trigger {
    margin: auto;
    cursor: pointer;
    position: relative;
    width: 100%;
    padding-top: 8px;
    
    &::before {
      display: block;
      width: 30px;
      height: 4px;
      margin: 0 auto 8px auto;
      background-color: #c9c9c9;
      border-radius: 2px;
      content: "";
    }
  }

  &--heading {
    text-align: center;
  }


  .content-wrapper {
    padding: 30px 23px 40px;
    font-family: VAG !important;
    font-weight: 300;
    max-height: 60%;
    line-height: 1.5em;
    overflow-y: auto;
    h2 {
      font-family: VAG !important;
      font-size: 32px !important;
    }
    & * {
      font-size: 20px !important;
      line-height: 1.5em;
    }
  }

  &.isFullscreen {
    background: #303030;
    color: white;
  }

}

.learning-exp-view {
  flex-grow: 1;
  flex-shrink: 0;
  padding-bottom: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;

  &.isFullscreen {
    left: 0;
    position: absolute;
    top: 0;
    background: black;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
}

.learn-animate-wrap {
  width: 100%;
  position: absolute;
}

.image-div {
  padding-top: 56.25%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.close-fullscreen {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 30px;
  color: white;
  z-index: 11;
  width: 30px;
  height: 30px;
}

.exp-description {
  padding: 10px 20px;

  * {
    font-family: VAG !important;
  }
}

.progress-dots-wrapper {
  width: 100%;
  position: absolute;
  bottom: 20%;
  display: flex;
  justify-content: center;

  .dot {
    height: 8px;
    width: 8px;
    background-color: #ababab;
    opacity: 0.5;
    margin-right: 5px;
    border-radius: 100%;

    &.isCurrent {
      opacity: 1;
    }
  }
}

.expandable-description {
  min-width: 40px;
  min-height: 40px;
  // position: absolute;
  z-index: 9;
  // left: 20px;
  // top: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
  &.isDescriptionExpanded {
    z-index: 15;
    opacity: 0.9;
    pointer-events: none;
    touch-action: none;
  }

  


  .inner-content {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 50px;
    overflow-y: auto;
    pointer-events: all;
    touch-action: auto;

    * {
      font-family: VAG !important;
      line-height: 1.5;
      color: inherit;
    }

    .close {
      position: fixed;
      left: 20px;
      top:20px;
      height: 30px;
      width: 30px;
    }
  }
}

.res-icon {
  height: 20px;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  left: 0;
}