@import 'styles/colors';

$box-size: 261px;

.dropzone-wrapper {
  width: $box-size;
  height: $box-size;
  border: 2px dashed $light-gray;
  border-radius: 5px;
  outline: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: 0.2s ease-in-out;
  cursor: pointer;

  &--small-text {
    color: $dark-gray;
  }

  &--upload-text {
    color: $friendly-green;

    transition: 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &:hover {
    background-color: $light-gray;
    border: 2px dashed $friendly-green;
  }

  .file-input {
    display: none;
  }
}

.box {
  margin: 20px;
}
