@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;

  .wrapper {
    width: 80%;
    max-width: 1000px;

    .text {
      font-size: 20px;
      line-height: 30px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 60px;

        &::before {
          position: absolute;
          content: '—';
          left: 0;
          font-size: 18px;
        }
      }
    }

    ol {
      padding-left: 20px;
      li {
        padding-left: 40px;

        &::marker {
          font-size: 20px;
        }
      }
    }

    strong {
      font-weight: 600;
    }

    a {
      &:link {
        color: $friendly-green;
      }
    }
  }
}



.logo {
  margin-bottom: 37px;
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  z-index: 1;
  cursor: pointer;

  @include mobile {
    padding: 30px 0 0 0;
  }
}
