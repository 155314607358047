@import 'styles/colors';

.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &--icon {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 40px;
    left: 15px;
  }
}

.section {
  .add-container {
    margin-top: 120px;
  }

  .row-texts-container {
    .classroom-name {
      max-width: 200px;
      white-space: nowrap;
    }
  }

  .creds-wrapper {
    .title-select {
      width: 115px;
    }
  }

  .disable-wrapper {
    width: 100%;
    border: 1px solid $alto;
    display: flex;
    flex-direction: column;

    .sub-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .disable-btn {
      width: 140px;
    }
  }

  .revoke-btn {
    width: 100%;
  }
}

.drawer-menu {
  display: flex;
  flex-direction: column;

  .drawer-option {
    display: flex;
    align-items: center;
    margin: 8px 0 8px 0;
    cursor: pointer;
  }
}

.menu-fab {
  position: fixed;
  right: 30px;
  bottom: 110px;
}
