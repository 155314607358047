@import 'styles/colors';

.wrapper {
  width: 100%;
  min-height: 440px;
  border: 2px dashed $light-gray;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  cursor: pointer;
  transition: all 0.2s ease;

  .icon {
    transition: all 0.2s ease;
  }

  .text {
    color: $friendly-green;
  }

  &:hover {
    background-color: $concrete;

    .icon {
      transform: scale(1.25);
    }
  }
}
