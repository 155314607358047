@import 'styles/colors';

.dots-wrapper {
  display: flex;
  height: 40px;
  width: 40px;
  background: rgba(0,0,0,0.1);
  transition: all .3s ease;
  border-radius: 100%;
  font-size: 14px;

  &:hover {
    background: rgba(0,0,0,0.5);
  }
}

.dots-icon {
  color: #fff;
  cursor: pointer;
  margin: auto;
  display: block;

  &.rotated {
    transform: rotate(90deg);
  }
}
