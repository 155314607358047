@import 'styles/colors';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 30px;
  user-select: none;

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    transition: all .3s ease;
    color: transparent;
    border: 1px solid $dark-gray;

    &.isChecked {
      color: $friendly-green;
      background-color: transparent;
      border-color: transparent;
    }
  }
}