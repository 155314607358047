@import 'styles/colors';
@import 'styles/variables';

.admin-cart-wrapper {
  width: 270px;
  min-width: 270px;
  border: 1px solid $alto;
  border-radius: 15px;
  overflow: hidden;

  @include mobile {
    width: 100%;
  }

  .upper-section {
    height: 100px;
    background-color: $concrete;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .admin-avatar {
      position: relative;
      width: 45px;
      height: 45px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: cover;

      .admin-badge {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);

        path {
          fill: #7d6706 !important;
        }
      }
    }

    .status-badge {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 3px 8px;
      border-radius: 3px;
      background-color: $friendly-green;
      color: $secondary-white;
      font-size: 11px;

      &.inactive {
        background-color: $orange;
      }
    }
  }

  .bottom-section {
    height: 125px;
    padding: 15px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
