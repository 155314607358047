@import 'styles/colors';

.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;

  .input {
    height: 48px;
    font-size: 15px;
    border: 1px solid $alto;
    padding: 0 8px;
    border-radius: 2px;
    transition: border-color, box-shadow 0.2s ease;

    &::placeholder {
      color: $silver-chalice;
    }

    &:disabled {
      background-color: $alabaster;
    }

    &:focus {
      border-color: $friendly-green;
      box-shadow: 0px 0px 0px 5px rgba(0, 177, 64, 0.2);
    }
  }

  .label-wrapper {
    font-size: 13px;
    line-height: 24px;
  }

  .password-label {
    display: flex;
    justify-content: space-between;
  }

  .eye-toggle {
    color: $friendly-green;
    user-select: none;
    display: flex;
    align-items: center;
    width: 55px;
    cursor: pointer;
    font-weight: 600;

    .eye-icon {
      margin-right: 5px;
      height: 18px;
      width: 18px;
    }
  }

  .error {
    // added absolute position relative to input due to jumping content
    position: absolute;
    top: 100%;
    color: $friendly-red;
    font-size: 12px;
  }

  &.has-error .input {
    border-color: $friendly-red;
  }

  .calendar-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 53%;

    pointer-events: none;
  }
}

.search-input-wrapper {
  height: fit-content;
  position: relative;

  .search-input {
    width: 190px;
    height: 40px;
    border: 1px solid $alto;
    border-radius: 34px;
    padding-left: 40px;
    font-size: 15px;
    font-family: Source Sans Pro;

    transition: width 0.2s ease;

    &:focus {
      width: 400px;
    }
  }

  .icon-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    border-radius: 34px 0 0 34px;

    transform: translateY(-50%);
    top: 50%;
    cursor: pointer;
  }

  .clear-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $dark-gray;

    .icon {
      width: 14px;
      height: 14px;
      cursor: pointer;

      path {
        fill: $secondary-white;
      }
    }
  }
}
