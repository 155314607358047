@import 'styles/colors';

.you-are-here {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: $secondary-white;
  padding: 1px 9px 0 3px;
  flex-shrink: 0;
  border-radius: 3px 0 0 3px;
  position: relative;
  background: url('../../icons/here-badge.svg');
  background-size: 100% 100%;
  width: 90px;
  height: 21px;
  z-index: 0;
}

.required-badge {
  color: #786820;
  z-index: 0;
  text-transform: uppercase;
  background-color: $joyeful-yellow;
  padding: 3px 5px;
  border-radius: 3px;
}

.draft-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: $sesame-black;
  background-color: $alto;
  border-radius: 5px;
  font-weight: 700;
  padding: 5px;

  &.size-xsm {
    font-size: 11px;
    height: 15px;
  }

  &.size-sm {
    font-size: 11px;
    height: 20px;
  }

  &.size-lg {
    font-size: 15px;
    height: 30px;
  }
}

.long-admin-badge {
  height: 22px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  background-color: $joyeful-yellow;

  .badge-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 20px;
    background-color: color-opacity($sesame-black, -0.8);

    .icon {
      margin-right: 6px;

      &.school-admin {
        margin-right: 3px;
      }

      path {
        fill: $secondary-white !important;
      }
    }
  }

  .text-wrap {
    height: 100%;
    padding: 4px 10px 6px 5px;
    font-size: 11px;
  }
}

.short-admin-badge {
  width: 22px;
  height: 22px;
  background-color: $joyeful-yellow;
  border-radius: 50%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);

  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
