@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  padding: 120px 62px 62px;
  max-width: 1400px;
  margin: auto;
  min-width: 768px;

  .classrooms-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.favorites-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-left: -20px;
  justify-content: flex-start;

  .favorite-card {
    display: flex;
    flex-direction: column;
    flex: 1 0 279px;
    max-width: 279px;
    height: 408px;
    margin: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease;

    &:hover {
      border-color: $friendly-green;
    }
  }

  .dropdown {
    margin-left: auto;
  }

  .image {
    height: 255px;
    position: relative;
    z-index: 0;
    transition: all 1s ease;
    padding: 10px;
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease;
    background-color: $alto;
    background-image: url('../../../icons/logo/StartWithSesame_V_Full.svg');
    cursor: pointer;
  }

  .image-backdrop {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 80%);
    pointer-events: none;
  }

  .title-col {
    padding: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .buttons-row {
    display: flex;
    padding: 10px 15px;
    justify-content: flex-end;
    margin-top: auto;
    border-top: 1px solid #ddd;
  }
}

.modal-wrapper {
  @include mobile {
    width: 90%;
  }

  .select-classroom {
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;

    @include mobile {
      padding-bottom: 20px;
    }

    .classroom-item {
      padding: 10px;
      border-radius: 5px;

      &:hover {
        background-color: $light-gray;
      }
    }
  }
}

.task-col {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 17px;

  .task-col-image {
    height: 56px;
    width: 56px;
    background: white;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 10px;
    flex-shrink: 0;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-end;
    background-size: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $alto;
    background-image: url('../../../icons/logo/StartWithSesame_V_Full.svg');


    .image {
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 80%);
    }
  }

  .task-name-col {
    padding: 28px 0;
    width: 100%;
    border-bottom: 1px solid $alto;
    overflow: hidden;
  }
}

.buttons-column {
  display: flex;
  justify-content: flex-end;

  .table-dropdown {
    background: transparent;
    margin-left: 20px;
    svg {
      color: $sesame-black !important;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.classroom-modal-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 17px;
  transition: all 0.3s ease;
  border-radius: 5px;

  &:hover {
    background: $alto;
  }

  .classroom-modal-image {
    height: 56px;
    width: 56px;
    background: gray;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 10px;
    flex-shrink: 0;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    align-items: center;

    .image {
      height: 100%;
      width: auto;
      position: absolute;
    }
  }

  .classroom-name-col {
    padding: 28px 0;
    width: 100%;
    border-bottom: 1px solid $alto;
    overflow: hidden;

    @include mobile {
      margin-right: 20px;
    }
  }
}

.tags-wrapper {
  display: flex;
  overflow-x: auto;

  .tag {
    margin-right: 5px;
  }
}
