@import 'styles/colors';

.text {
  font-family: VAG;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;

  &--bold {
    font-weight: 600;
  }

  &--tag-h1 {
    font-size: 40px;
  }

  &--tag-h2 {
    font-size: 32px;
  }

  &--tag-h3 {
    font-size: 28px;
  }

  &--tag-h4 {
    font-size: 24px;
  }

  &--tag-h5 {
    font-size: 20px;
  }

  &--tag-h6 {
    font-size: 18px;
    line-height: 1.3em;
  }

  &--tag-h7 {
    font-size: 16px;
  }

  &--tag-h8 {
    font-size: 16px;
    font-family: Adele;
  }

  &--tag-h9 {
    font-size: 14px;
  }

  &--tag-h10 {
    font-size: 14px;
    font-family: Adele;
  }

  &--tag-h11 {
    font-size: 12px;
  }

  &--tag-body {
    font-size: 15px;
    font-family: Source Sans Pro;
    &-big {
      font-size: 18px;
      font-family: Source Sans Pro;
    }

    &-small {
      font-size: 13px;
      font-family: Source Sans Pro;
    }

    &-xs {
      font-size: 10px;
      font-family: Source Sans Pro;
    }
  }

  &.action {
    font-weight: 600;
    color: $friendly-green;
    cursor: pointer;
  }
}
