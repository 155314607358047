@import 'styles/colors';

.wrapper {
  margin: 12px 0 16px 0;

  &--main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      text-align: center;
      max-width: 70vw;
    }

    .breadcrumbs {
      font-family: Source Sans Pro;
      color: $dark-gray;
      text-align: center;
    }
  }

  &--chevron {
    position: absolute;
    top: 43px;
    left: 15px;
  }

  &--dots {
    position: absolute;
    top: 43px;
    right: 30px;
  }
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &--subsection {
    margin: 10px 0;
    display: inline-flex;
    align-items: center;

    cursor: pointer;
  }
}
