@import 'styles/variables';
@import 'styles/colors';

.wrapper {
  overflow: hidden;

  ul {
    list-style-type: none;
  }
  ul:not([style]) li {
    position: relative;
  }

  ul:not([style]) li::before {
    content: '';
    display: inline-block;
    height: 10px;
    width: 10px;
    border: 1px solid;
    border-radius: 2px;
    position: absolute;
    left: -20px;
    top: 7px;
  }
}

.header {
  display: flex;
  width: 100%;
  height: 72px;
  padding: 12px 0 12px 30px;
  border-bottom: 1px solid #c9c9c9;
  justify-content: space-between;
  align-items: center;

  .right-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  .close-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    border-left: 1px solid #c9c9c9;
    height: 100%;
    margin-left: 15px;

    .close-icon {
      cursor: pointer;
    }
  }

  .fullscreen-icon {
    cursor: pointer;
  }
}

.learning-body {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  position: relative;
  transition: background 0.3s ease;
  width: 100%;

  .bottom-description {
    background-color: #fff;
    transition: background 0.3s ease;
  }

  .learning-exp-view-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.isFullscreen {
    position: fixed;
    height: 100%;
    z-index: 10;
    background: black;
    top: 0;

    .inner-content-wrapper {
      height: 100%;

      @media screen and (max-height: 655px) {
        max-width: calc(65vh * 1.66);
      }
    }

    .learning-exp-view-wrapper:not(.withPadding) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .bottom-draw {
      .bottom-description {
        background: black;
        color: white;
      }
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;

  span {
    display: flex;
    margin: 0 5px;
    padding-bottom: 3px;
  }
}

.order-label {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  position: relative;
}

.learning-exp-view-wrapper {
  padding: 0 10% 50px;
  height: 100%;
  transition: all 0.3s ease;
  overflow-y: auto;

  &.withPadding {
    padding: 0 22%;
  }

  .inner-content-wrapper {
    position: relative;
    width: 100%;
    // height: calc(100% - 70px);
    // padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    // max-width: 1250px;
    max-width: calc(75vh * 1.66);
    transition: all 0.3s ease;
    // align-self: center;

    &.isExpanded {
      // align-self: flex-start;
      max-width: calc(52vh * 1.66);
    }

    @media screen and (max-height: 655px) {
      & {
        max-width: calc(50vh * 1.66);

        &.isExpanded {
          max-width: calc(44vh * 1.66);
        }
      }
    }

    & * {
      font-family: VAG !important;
      strong {
        font-weight: 800;
      }
    }
  }

  .image-div-wrapper {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    height: auto;
    z-index: 0;

    .image-div {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}
.bottom-draw {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

.responsive-iframe {
  border: 0;
  width: 100%;
  background: black;
  display: flex;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &__back {
    left: 20px;
  }

  &__forward {
    right: 20px;
    transform: rotate(-180deg) translateY(50%);
  }
}

.trigger {
  height: 22px;
  background-color: $friendly-green;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: $friendly-green;
  }

  &-expanded {
    width: 30px;
    height: 3px;
    background-color: #fff;
  }

  &-collapsed {
    display: flex;
    position: absolute;
    top: -15px;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 25px;
    border-radius: 3px;
    background: $friendly-green;
    color: white;

    .icon {
      transform: rotate(180deg);
    }
  }

  &.isFullscreen {
    background: $dark-gray;
  }

  &.isFullscreen .trigger-collapsed {
    background: $dark-gray;
  }
}

.resources-nav {
  position: absolute;
  top: 0;
  right: -300px;
  height: 100%;
  width: 400px;
  z-index: 10;
  display: flex;
  pointer-events: none;

  .button-wrapper {
    width: 100px;
    height: 100%;
    pointer-events: none;

    .button {
      display: flex;
      height: 40px;
      width: 80px;
      background: white;
      color: black;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px;
      margin: 20px 10px 0 auto;
      border-radius: 40px;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
      pointer-events: all;

      &.isExpandedRes {
        padding: 0;
        width: 40px;
        justify-content: center;

        &:hover {
          color: white;
          background-color: black;
        }
      }
    }
  }

  &.isFullscreen {
    margin-top: 70px;

    .button {
      background: rgba(0, 0, 0, 0.4);
      border: 1px solid white;
      color: white;

      &.isExpandedRes {
        &:hover {
          color: black;
          background-color: white;
        }
      }
    }
  }

  .content-wrapper {
    padding: 20px 40px;
    background-color: #fff;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    overflow-y: auto;
    width: 300px;
    border-left: 1px solid #ddd;
    pointer-events: all;
    touch-action: auto;

    &.isFullscreen {
      background-color: black;
      color: white;
    }
  }
}

.inside-bottom {
  width: 90%;
  border-radius: 10px;
  padding: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15%;
  // background-color: red;
  opacity: 0.85;
  display: flex;
  padding: 3%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-overflow: ellipsis;
  font-size: calc(0.8vh + 0.8vw);
  display: flex;
  font-family: VAG !important;
  line-height: 1.5em;
  overflow-y: auto;
  max-height: 50%;

  a {
    color: inherit !important;
  }
  & * {
    font-family: VAG !important;
  }
}

.inside-top {
  border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  // background-color: red;
  opacity: 0.85;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(0.8vh + 0.8vw);
  display: flex;
  overflow: hidden;
  font-family: VAG !important;
  padding: 3%;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 1.5em;
  overflow-y: auto;
  max-height: 50%;

  & * {
    font-family: VAG !important;
  }
  a {
    color: inherit !important;
  }
}

.inside-center {
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // background-color: red;
  opacity: 0.85;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(0.8vh + 0.8vw);
  display: flex;
  overflow: hidden;
  font-family: VAG !important;
  padding: 3%;
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 35%;
  line-height: 1.5em;
  overflow-y: auto;
  max-height: 56%;

  & * {
    font-family: VAG !important;
  }
  a {
    color: inherit !important;
  }
}

.inside-left {
  top: 50%;
  transform: translateY(-50%);
  left: 3%;
  padding: 3%;
  width: 25%;
  height: 60%;
  border-radius: 10px;
  position: absolute;
  // top: 50%;
  // transform: translateY(calc(-50% - 25px));
  // background-color: red;
  opacity: 0.85;
  font-size: calc(0.8vh + 0.8vw);
  padding: 40px;
  font-family: VAG !important;
  line-height: 1.5em;
  overflow-y: auto;
  & * {
    font-family: VAG !important;
  }
  a {
    color: inherit !important;
  }
}

.inside-right {
  top: 50%;
  transform: translateY(-50%);
  padding: 3%;
  width: 25%;
  height: 60%;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  right: 3%;
  // transform: translateY(calc(-50% - 25px));
  // background-color: red;
  opacity: 0.85;
  font-size: calc(0.8vh + 0.8vw);
  padding: 40px;
  font-family: VAG !important;
  line-height: 1.5em;
  overflow-y: auto;
  & * {
    font-family: VAG !important;
  }
  a {
    color: inherit !important;
  }
}

.outside-right {
  padding: 40px;
  width: 40%;
  border-radius: 10px;
  // position: absolute;
  // top: 50%;
  height: 100%;
  margin-left: 5%;
  // transform: translateY(calc(-50% - 25px));
  // background-color: red;
  opacity: 0.85;
  flex-shrink: 0;
  overflow-y: auto;
  font-size: calc(0.8vh + 0.8vw);
  font-family: VAG !important;
  padding: 40px;
  line-height: 1.5em;
  & * {
    font-family: VAG !important;
  }
  a {
    color: inherit !important;
  }
}

.outside-left {
  padding: 40px;
  width: 40%;
  height: 100%;
  border-radius: 10px;
  // position: absolute;
  // top: 50%;
  margin-right: 5%;
  right: 3%;
  // transform: translateY(calc(-50% - 25px));
  // background-color: red;
  opacity: 0.85;
  overflow-y: auto;
  flex-shrink: 0;
  font-family: VAG !important;
  font-size: calc(0.8vh + 0.8vw);
  padding: 3%;
  line-height: 1.5em;

  & * {
    font-family: VAG !important;
  }
  a {
    color: inherit !important;
  }
}

.learn-animate-wrap {
  height: calc(100% - 60px);
  width: 100%;
  position: absolute;
  pointer-events: all;

  &.isFullscreen {
    padding: 60px;
    padding-top: 0;
  }
}

.relative-wrapper {
  flex-grow: 1;
  flex-basis: 50%;
  position: relative;
  pointer-events: none;
  width: 100%;
}

.close-fullscreen {
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 11;
}

.hint {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  background: black;
  color: white;
  border-radius: 5px;
  padding: 10px 15px;
  border: 1px solid white;
  display: flex;
  z-index: 12;
  justify-content: center;
  line-height: 100%;

  .esc {
    padding: 0 2px;
    border: 1px solid white;
    font-size: 12px;
    margin: 0 5px;
  }

  .key {
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    margin-right: 5px;

    &__right {
      transform: rotate(180deg);
    }
  }
}

.resource {
  display: block;
  pointer-events: all;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  padding-left: 25px;

  margin: 10px 0;

  .link-icon {
    position: absolute;
    right: 0;
    color: $friendly-green;
    transition: all 0.3s ease;
  }

  &.isFullscreen {
    &:hover .link-icon {
      color: $friendly-green;
    }

    .link-icon {
      color: white;
    }
  }
}

.bottom-description {
  padding: 60px 20%;
  font-size: 32px;
  font-family: VAG !important;
  font-weight: 300;
  line-height: 1.5em;
  h1 {
    font-size: 38px !important;
  }
  h2 {
    font-family: VAG !important;
    font-size: 32px !important;
  }
  h3 {
    font-size: 26px !important;
  }
  & * {
    font-size: 20px;
    line-height: 1.5em;
  }
  & em {
    font-size: inherit !important;
  }
}

:global {
  p[data-f-id='pbf'] {
    display: none !important;
  }
}

// .noMaxWidth {
//   max-width: unset !important;
// }

.res-icon {
  height: 20px;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  left: 0;
}

// .inside-left,
// .outside-left,
// .inside-right,
// .outside-right {
//   & p {
//     margin-bottom: 1em;
//   }
// }

@media screen and (min-width: 2000px) {
  // .inside-left,
  // .outside-left,
  // .inside-right,
  // .outside-right {
  //   font-size: 1.5em;
  // }
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inside-desc {
  min-height: 1px;
}

.draft-badge {
  margin-top: 1px;
  margin-left: 5px;
}
