@import 'styles/variables';
@import 'styles/indents';

@font-face {
  font-family: 'VAG';
  src: url(./fonts/VAG.woff) format('woff');
}

@font-face {
  font-family: 'VAG';
  src: url(./fonts/VAG.otf) format('opentype');
  font-weight: bold;
}

//
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Bold.woff2') format('woff2'), url('./fonts/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-ExtraBold.woff2') format('woff2'), url('./fonts/OpenSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-BoldItalic.woff2') format('woff2'), url('./fonts/OpenSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Regular.woff2') format('woff2'), url('./fonts/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Light.woff2') format('woff2'), url('./fonts/OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-SemiBold.woff2') format('woff2'), url('./fonts/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Italic.woff2') format('woff2'), url('./fonts/OpenSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-LightItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-BoldItalic.woff2') format('woff2'),
    url('./fonts/SourceSansPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Black.woff2') format('woff2'), url('./fonts/SourceSansPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Bold.woff2') format('woff2'), url('./fonts/SourceSansPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
    url('./fonts/SourceSansPro-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
    url('./fonts/SourceSansPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-BlackItalic.woff2') format('woff2'),
    url('./fonts/SourceSansPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Italic.woff2') format('woff2'),
    url('./fonts/SourceSansPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Regular.woff2') format('woff2'),
    url('./fonts/SourceSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
    url('./fonts/SourceSansPro-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Light.woff2') format('woff2'), url('./fonts/SourceSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
    url('./fonts/SourceSansPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-LightItalic.woff2') format('woff2'),
    url('./fonts/SourceSansPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-SemiBold.woff2') format('woff2'), url('./fonts/Adelle-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-Light.woff2') format('woff2'), url('./fonts/Adelle-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-LightItalic.woff2') format('woff2'), url('./fonts/Adelle-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-BoldItalic.woff2') format('woff2'), url('./fonts/Adelle-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-Regular.woff2') format('woff2'), url('./fonts/Adelle-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-SemiBoldItalic.woff2') format('woff2'),
    url('./fonts/Adelle-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-Heavy.woff2') format('woff2'), url('./fonts/Adelle-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-HeavyItalic.woff2') format('woff2'), url('./fonts/Adelle-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-Bold.woff2') format('woff2'), url('./fonts/Adelle-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/Adelle-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-ExtraBold.woff2') format('woff2'), url('./fonts/Adelle-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Adelle';
  src: url('./fonts/Adelle-Italic.woff2') format('woff2'), url('./fonts/Adelle-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

//

* {
  box-sizing: border-box;
  font-family: Source Sans Pro;
}

.rdw-link-decorator-icon {
  z-index: 100;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

#root {
  min-width: 320px;
  min-height: 100%;
  height: 100%;
  // height: -webkit-fill-available;
  // padding: $notch-top $notch-right $notch-bottom $notch-left;
  width: 100%;
  position: relative;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

textarea,
input {
  outline: none;
}

.m-auto {
  margin: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mh-auto {
  margin-right: auto;
  margin-left: auto;
}

.flex {
  display: flex;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.AppContainer {
  min-height: 100%;
  position: relative;

  .app-router {
    padding-bottom: 60px;

    &.isLearningExp {
      padding-bottom: 0;
    }

    @include mobile {
      padding-bottom: 0px;
    }
  }

  @include mobile {
    padding-bottom: 90px;
    // overflow: auto;
  }
}

.Loader-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
