@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  .main-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    // padding-top: 30px;

    &--chapter {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 15px;
      line-height: 28px;
      color: #151515;
      position: relative;

      &-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        margin: 24px 0;
        line-height: 40px;
        padding-right: 10px;

        &--button {
          transform: translate(10px, 5px);
        }
      }

      &-image {
        border-radius: 5px;
      }
    }

    &--image-wrapper {
      .image-caption {
        font-family: Source Sans Pro;
        line-height: 20px;
        color: $dark-gray;
        margin-bottom: 32px;
      }
    }

    &--pre_k3 {
      width: 100%;

      table:not(:global(.sel_related)) {
        thead {
          background-color: $purple;
        }
      }
    }

    &--pre_k4 {
      width: 100%;

      table:not(:global(.sel_related)) {
        thead {
          background-color: $thoughtful-teal;
        }
      }
    }
  }

  ul {
    list-style-type: none;
  }

  ul:not([style]) li {
    position: relative;
  }

  ul:not([style]) li::before {
    content: '';
    display: inline-block;
    height: 10px;
    width: 10px;
    border: 1px solid;
    border-radius: 2px;
    position: absolute;
    left: -20px;
    top: 7px;
  }
}

.nav-menu {
  &--heading {
    margin-bottom: 15px;
  }
  &--text {
    line-height: 32px;
    padding: 3px 0;

    cursor: pointer;

    &--active {
      color: $friendly-green;
    }
  }
}
