@import 'styles/colors';

.image-upload {
  width: 100%;
  height: 125px;
  border: dashed 2px $light-gray;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &--icon {
    margin-top: -10px;
  }

  &--input {
    display: none;
  }
}

.images-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 100px;
}

.bottom-menu {
  display: flex;
  flex-direction: column;

  &--delete {
    width: 100%;
    margin-top: 10px;
    border-color: $sesame-black;
    background-color: $sesame-black;

    &:hover {
      background-color: $secondary-white;
      color: $sesame-black;
    }
  }

  &--save {
    width: 100%;
    margin-top: 20px;
  }
}
