@import 'styles/colors';

.wrapper {
  position: relative;
  border-radius: 100%;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  background-clip: content-box;
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  border: 1px solid transparent;
  flex-shrink: 0;

  &.bordered {
    border-color: $light-gray;
  }

  &.small {
    height: 25px;
    width: 25px;
  }
  &.regular {
    height: 39px;
    width: 39px;
  }
  &.large {
    height: 39px;
    width: 39px;
  }

  .avatar {
    height: 100%;
  }

  .hover-filter {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.3s ease;
  }

  &:hover .hover-filter {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
