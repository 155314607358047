@import 'styles/colors';

.wrapper {
  max-width: 1400px;
  min-width: 768px;
  margin: auto;
  
  @media screen and (max-width: 425px) {
    min-width: 300px;
  }
}

.welcome-section {
  padding: 40px 62px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 700px;

  .logo {
    margin-bottom: 90px;
  }

  .message-wrapper {
    max-width: 440px;
    
    .title {
      margin-bottom: 20px;
    }

    .button {
      width: 220px;
      font-size: 18px;
      margin-top: 30px;
    }
  }
}


.relative-sections {
  position: relative;
}

.nav-absolute {
  position: absolute;
  max-width: 40%;
  min-width: 280px;
  width: 100%;
  top: 0;
  right: 0;
  height: 100%;
  padding-left: 40px;

  .fixed-nav {
    position: sticky;
    top: 100px;

    .nav-content {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .current-step {
        font-size: 36px;
        color: $silver;
        font-family: VAG;
        display: flex;
        
        .big-number {
          font-size: 100px;
          font-family: VAG;
          color: black;
          line-height: 1em;
          margin-right: 5px;
        }
      }

      .anchor-steps {
        margin-top: 40px;

        .anchor {
          margin-bottom: 15px;
          cursor: pointer;
          color: $silver;
          font-weight: 500;

          &.isActive {
            font-weight: 600;
            color: $friendly-green;
          }
        }
      }
    }
  }
}

.section {
  max-width: 60%;
  min-width: 500px;
  padding-top: 100px;
}

.section1 {
  transition: all .3s ease;
  &.expanded {
    max-width: 100%;
  }
  .form1 {
    width: 415px;
    margin: auto;

    .title-select {
      width: 100px;
    }

    .next-step {
      width: 130px;
    }
  }
}

.section2 {

  .form2 {
    width: 415px;
    margin: auto;
  }
  
  .box-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .box {
    height: 186px;
    width: 186px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $concrete;

    .number {
      font-size: 56px;
      font-family: VAG;
      margin-bottom: 15px;
      line-height: 1em;
    }

    .text {
      color: $dark-gray;
    }
  }

  .next-step {
    width: 120px;
  }
}

.form4 {
  width: 415px;
  margin: auto;
}

.section3 {

  .form3 {
    width: 415px;
    margin: auto;
  }


  .brake-line {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .email-array {

    .email-box {
      width: 100%;
      background-color: $concrete;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 13px;
      color: $dark-gray;
      margin-bottom: 10px;
      
      .delete-ico {
        cursor: pointer;
      }
    }

    .add-email {
      display: flex;
      color: $friendly-green;
      cursor: pointer;
      margin-top: 20px;
    }

    .plus {
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;

      .email-button {
        width: 90px;
      }
    }
  }
}


.green {
  color: $friendly-green !important;
  opacity: 0.6;
}

@media screen and (max-width: 425px) {
  .nav-absolute {
    display: none;
  }

  .welcome-section {
    padding: 40px;
    text-align: center;

    .logo {
      margin-bottom: 190px;
    }
  }

  .title {
    font-size: 28px;
  }

  .section1, .section2, .section3 {
    min-width: 0px;
    max-width: 100%;
    padding: 44px 30px;
  }

  .section2 .box {
    height: 146px;
    width: 146px;
    margin-right: 20px;
  }
  
  .section1, .section2, .section3 {
    & button {
      margin-top: auto;
      margin-left: 0;
    }
  }

  .form1, .form2, .form3 {
    width: auto;
    max-width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
  }
}