@import 'styles/colors';
@import 'styles/variables';

.container {
  padding: 120px 62px 62px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 1440px;
  margin: auto;

  .breadcrumbs {
    display: flex;
    align-items: flex-start;

    .crumb {
      color: $dark-gray;

      &.link {
        cursor: pointer;
        text-decoration: underline;
        transform: opacity 0.2s ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .chevron {
      width: 20px;
      height: 20px;
      transform: rotate(-90deg) scale(0.7);

      path {
        fill: $dark-gray;
      }
    }
  }
}

.page-hero {
  display: flex;
  width: 100%;
  background-color: #e3e3e3;
  height: 260px;
  padding: 20px 40px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);

  .avatar {
    height: 56px;
    width: 56px;
    border: 1px solid #fff;
    margin-right: 15px;
    border-radius: 10px;
    background-size: 100px auto;
    background-repeat: no-repeat;
    background-position: -30px bottom;
    flex-shrink: 0;
  }

  .avatar-row {
    display: flex;
    align-items: center;
    .name-wrapper {
      color: white;
    }
  }

  .week-row {
    color: #ffffff70;
    display: flex;
    align-items: center;

    .launch-week-btn {
      background-color: transparent;
      border-color: white;
      margin-left: 15px;
      height: 32px;

      &:hover {
        background-color: #fff;
        color: black;
      }
    }
  }

  .left-content {
    height: 100%;
    flex: 1 0 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .right-content {
    flex: 1 1 55%;

    .image-wrapper {
      position: relative;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      border: 1px dashed white;
      display: flex;
      justify-content: center;
      align-items: center;

      .cover-image {
        position: absolute;
        height: 100%;
        z-index: 0;
      }

      .change-image {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #15151580;
        color: #fff;
        font-size: 18px;
        padding: 15px 25px 15px 17px;
        width: 240px;
        border-radius: 30px;
        cursor: pointer;
        border: 2px solid transparent;
        transition: all 0.3s ease;

        &:hover {
          border-color: white;
        }

        .text {
          margin-left: 15px;
        }
      }
    }
  }
}

.left-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;

  .form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 300px;
    flex-shrink: 0;

    &:first-child {
      margin-right: 40px;
    }

    &:last-child {
      width: 100%;
    }

    .button {
      margin-top: 40px;
      width: 135px;
    }
  }

  .disable-wrapper {
    width: 640px;
    max-width: 100%;
    border: 1px solid $alto;
    display: flex;
    justify-content: space-between;

    .sub-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.tab-switch-wrap {
  margin: 0 -62px;
  position: relative;

  .add-teachers-button {
    position: absolute;
    right: 62px;
    top: 80px;

    .icon-label {
      display: flex;
      align-items: center;

      svg {
        path {
          transition: fill 0.3s ease-in-out;
        }
      }

      &:hover {
        svg {
          path {
            fill: $friendly-green !important;
          }
        }
      }
    }
  }
}

.teachers-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
}

.user-col {
  display: flex;
  align-items: center;
  padding-bottom: 0 !important;
  padding-right: 0 !important;

  &__image {
    .img-icon-wrapper {
      width: fit-content;
      height: fit-content;
      position: relative;

      .image {
        width: 45px;
        height: 45px;
        border-radius: 10px;
      }

      .badge-people {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(50%, 50%);

        path {
          fill: $secondary-white !important;
        }
      }
    }
  }
}

.user-name-col {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-gray;
  padding-bottom: 18px;
}

.dots-col {
  display: flex;
  justify-content: flex-end;

  .table-dropdown {
    background-color: transparent;

    svg {
      path {
        fill: $sesame-black;
      }
    }
  }
}

.warn-text {
  color: $friendly-red;
}
