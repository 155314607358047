@import 'styles/colors';
@import 'styles/variables';

$arrow-wrapper-diameter: 40px;
$dots-wrapper-height: 8px;
$dot-diameter: 8px;

.wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;

  &.mobile {
    min-width: unset;
    min-height: unset;
    width: 100%;
  }
}

:global(.BrainhubCarousel) {
  padding-bottom: 42.86%;
  position: relative;

  &__trackContainer {
    position: absolute;
    top: 0;
  }

  &Item {
    align-items: flex-start !important;
  }
}

.left-arrow--wrapper,
.right-arrow--wrapper {
  width: $arrow-wrapper-diameter;
  height: $arrow-wrapper-diameter;
  background-color: $secondary-white-08;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &.mobile {
    display: none;
  }
}

.left-arrow--wrapper {
  position: absolute;
  left: 20px;
  top: 50%;

  transform: translateY(-50%);
}

.right-arrow--wrapper {
  position: absolute;
  right: 20px;
  top: 50%;

  transform: translateY(-50%);
}

.arrow-right {
  transform: rotate(180deg);
}

.dots-container {
  position: absolute;
  left: 50%;
  bottom: 24px;

  transform: translateX(-50%);

  &.mobile {
    display: none;
  }
}

.dots-wrapper {
  height: $dots-wrapper-height;

  display: inline-flex;

  .dot-element {
    width: $dot-diameter;
    height: $dot-diameter;
    border-radius: 50%;
    background-color: $secondary-white-08;
    margin: 0 2px;

    &--active {
      background-color: $secondary-white;
    }
  }
}
