@import 'styles/colors';

.warn-text {
  color: $friendly-red;
}

.cover-wrapper {
  width: 100vw;
  transform: translate(-30px, -30px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .chevron-wrapper {
    position: fixed;
    top: 43px;
    left: 15px;
    z-index: 5;

    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: $sesame-black;
    opacity: 0.7;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &--icon {
      color: $secondary-white;
      width: 35px;
      height: 35px;

      transform: rotate(90deg) translateY(2px);
    }
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
      color: $secondary-white;
      margin: 3px 0;
    }

    h6 {
      max-width: 200px;
      white-space: nowrap;
    }
  }

  .change-image {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #15151580;
    color: #fff;
    font-size: 18px;
    padding: 5px 10px 5px 8px;
    width: 180px;
    border-radius: 30px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.3s ease;

    &:hover {
      border-color: white;
    }

    .text {
      margin-left: 15px;
      font-size: 13px;
    }
  }

  &--image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
}

.section {
  margin-top: 180px;

  .row-texts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    * {
      max-width: 200px;
      white-space: nowrap;
    }
  }

  form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .input-group {
      width: 100%;

      .standard-input {
        max-width: 300px;
      }
    }
  }

  .disable-wrapper {
    width: 100%;
    border: 1px solid $alto;
    display: flex;
    flex-direction: column;

    .sub-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .disable-btn {
      width: 180px;
    }
  }
}

.menu-fab {
  position: fixed;
  right: 30px;
  bottom: 110px;
}

.drawer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .drawer-option {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 0;
  }
}
