@import 'styles/colors';

.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;

  .label-wrapper {
    font-size: 13px;
    line-height: 24px;
  }

  .select-control {
    height: 48px;
    border: 1px solid $light-gray;
    border-radius: 2px;
    box-shadow: none;

    &:hover {
      border: 1px solid $light-gray;
    }

    &--error {
      border: 1px solid $friendly-red;

      &:hover {
        border: 1px solid $friendly-red;
      }
    }

    &.open {
      border-color: $friendly-green;
      box-shadow: 0px 0px 0px 5px rgba(0, 177, 64, 0.2);
    }
  }

  .option-wrapper {
    position: relative;

    .select-option {
      background-color: unset;
      color: $sesame-black;
      height: 40px;

      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: $snow;
      }
    }

    .check-icon {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      path {
        fill: $friendly-green;
      }
    }
  }

  .error {
    color: $friendly-red;
    font-size: 12px;
  }
}
