@import 'styles/colors';
@import 'styles/variables';
@import 'react-toastify/dist/ReactToastify.min.css';

.Toastify {
  font-weight: bold;
  text-align: center;

  &__toast-container--bottom-left {
    bottom: $footer-height;
  }

  &__toast {
    font-size: 13px;
    border-radius: 5px;
    width: 215px;

    @include mobile {
      width: 90%;
      bottom: calc(#{$mobile-nav-height} + 30px);
      min-height: auto;
      margin: auto;
      padding: 10px 0;
    }

    &--success {
      background-color: $friendly-green;
    }

    &--error {
      background-color: $error-red;
    }
  }
}
