@import 'styles/colors';
@import 'styles/variables';

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 30;
  background-color: rgba(30, 38, 62, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
  overflow-y: auto;

  @include mobile {
    position: fixed;
  }

  .animation-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.modal {
  max-width: 100%;
  width: 600px;
  background-color: white;
  border-radius: 5px;
  pointer-events: all;
  z-index: 30;

  &.auto-width {
    width: auto;
  }

  &.isBottomLocated {
    width: 90%;
    max-width: 315px;
    position: absolute;
    bottom: 30px;
  }

  @include mobile {
    width: auto;
    border-radius: 15px;
  }
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 15px;
  margin: 0;
}

.modal-body {
  @include mobile {
    padding: 0;
  }

  &.simple {
    padding: 10px 25px 20px 25px;
  }
}

.modal-header {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid $alto;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  min-height: 72px;
  height: 72px;
  padding: 12px 20px;

  &.friendly {
    background-color: $friendly-green;
    border: none;
    padding: 20px 25px;
    height: unset;

    * {
      color: $secondary-white;
    }
  }

  &.simple {
    border: none;
    padding: 30px 25px 20px 25px;
  }

  &.overflow-visible {
    overflow: visible;
  }
}

.modal-footer {
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  padding: 25px 30px;
  display: flex;
  justify-content: flex-end;

  &.friendly {
    background-color: $snow;
    border-top: 1px solid $alto;
    padding: 15px 20px;
  }

  &.checkable {
    justify-content: space-between;
    align-items: center;
    background-color: $snow;

    .checkbox-wrap {
      display: flex;
      align-items: center;

      cursor: pointer;

      .checkbox {
        width: 20px;
        height: 20px;
        border: 2px solid $silver-chalice;
        border-radius: 50%;

        transition: background-color 0.2s ease;

        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          width: 20px;
          height: 20px;

          path {
            fill: $secondary-white;
          }
        }

        &.checked {
          background-color: $friendly-green;
          border-color: $friendly-green;
        }
      }

      label {
        font-size: 15px;
        color: $dark-gray;

        cursor: pointer;
      }
    }
  }
}

.close-button {
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 100%;
  border-left: 1px solid $alto;

  @include mobile {
    padding-left: 15px;
  }

  .icon:hover {
    background-color: $alto;
    border-radius: 2px;
    transition: background-color 0.2s ease;
    cursor: pointer;
  }

  &.friendly {
    border: none;

    .icon {
      &:hover {
        background-color: unset;
      }
    }
  }

  &.simple {
    border: none;

    .icon {
      width: 32px;
      height: 32px;

      &:hover {
        background-color: unset;
      }
    }
  }
}

.warning-modal {
  width: 700px;

  .body-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 10px 120px;

    .image {
      width: 100%;
      height: 200px;
    }
  }

  @include mobile {
    width: 90vw;
    min-height: 95vh;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .body-wrapper {
      padding: 10px 20px;
    }

    .body-footer {
      display: flex;
      flex-direction: column;
      background-color: unset;

      .buttons-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        button {
          margin: 10px 0;
          width: 100%;
        }
      }
    }
  }
}
