@import 'styles/colors';

$icon-side-length: 15px;

.wrapper {
  padding: 1px 5px;
  border: 1px solid $dark-gray;
  border-radius: 3px;

  display: inline-flex;
  align-items: center;

  &--required {
    border: none;
    background-color: $joyeful-yellow;
  }

  .badge-text {
    font-family: Source Sans Pro;
    text-transform: uppercase;
    color: $dark-gray;
  }

  .icon-component {
    margin-right: 6px;
    color: $dark-gray;
    width: $icon-side-length;
    height: $icon-side-length;
  }
}
