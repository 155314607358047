@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  background-color: $wild-sand;
  min-height: calc(100vh - #{$tl-navbar-height + 3 / 2 * $footer-height});
  padding: 60px calc((100% - 1400px) / 2);

  @include mobile {
    padding: 0 !important;
  }

  .container {
    height: 600px;
    width: 100%;
    border: solid 1px $light-gray;
    border-radius: 10px 10px 5px 5px;
    background-color: $secondary-white;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: relative;

    &.is-ie {
      margin-top: 130px;
    }

    @include mobile {
      border: none;
      justify-content: unset;
      margin-top: 40px;
    }

    .upper-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 12px;
      border-radius: 5px 5px 0 0;

      @include mobile {
        display: none;
      }
    }

    .editor-wrapper {
      width: 80%;
      position: relative;

      @include mobile {
        width: 100%;
        margin: 20px 0 0 0;
      }

      .editor {
        padding: 20px;
        border: 1px solid $alto;
        border-radius: 0 0 5px 5px;

        font-size: 15px;
        font-family: Source Sans Pro;
        color: $sesame-black;
      }

      &--saved {
        position: absolute;
        bottom: -30px;
        left: 0;

        color: $charcoal;
      }
    }
  }
}

.hidden {
  display: none;
}
