@import 'styles/colors';

.wrapper {
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;
  top: 0;
  left: 0;
  
  .modal {
    background-color: white;
    border-radius: 5px;
    padding: 20px 40px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;


    .icon {
      margin-right: 15px;
      height: 40px;
      width: 40px;
      transform: rotate(45deg);
        // animation: rotateToPortrait 3s ease infinite;
    }

    &.isPortrait {
      .icon {
        transform: rotate(135deg);
        animation: rotateToPortrait 3s ease infinite;
      }
    }
  }
}

@keyframes rotateToPortrait { 
	20% { transform: rotate(45deg); } 
	60% { transform: rotate(45deg); } 
	100% { transform: rotate(135deg); } 
}
@keyframes rotateToLandscape { 
	20% { transform: rotate(45deg); } 
	60% { transform: rotate(45deg); } 
	100% { transform: rotate(135deg); } 
}