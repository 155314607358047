@import 'styles/colors';

.container {
  .header {
    .cover-wrapper {
      width: 100%;
      height: fit-content;

      &--image {
        width: 100%;
        height: 100%;
      }
    }

    .chevron-wrapper {
      position: fixed;
      top: 43px;
      left: 15px;
      z-index: 5;

      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: $sesame-black;
      opacity: 0.7;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;

      &--icon {
        color: $secondary-white;
        width: 35px;
        height: 35px;

        transform: rotate(90deg) translateY(2px);
      }
    }

    .headings {
      position: fixed;
      top: 45px;
      left: 50%;
      color: $secondary-white;
      text-align: center;

      z-index: 5;
      transform: translateX(-50%);

      &--name {
        max-width: 50vw;
        white-space: nowrap;
      }
    }
  }

  .content-wrapper {
    .bread-crumbs {
      color: $dark-gray;
      font-family: Adele;
      opacity: 0.7;
    }

    .unit-select {
      width: 100%;
      height: 65px;
      border: solid 1px $alto;
      border-radius: 10px;
      overflow: hidden;

      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .left-section {
        max-width: 240px;
        padding: 10px;

        &--text {
          white-space: nowrap;
        }
      }

      .right-section {
        width: 65px;
        border-left: solid 1px $alto;
        background-color: $concrete;

        position: relative;

        .circle {
          width: 30px;
          height: 30px;

          position: absolute;
          top: 50%;
          left: 50%;

          transform: translate(-50%, -50%);
        }

        .chevron {
          width: 20px;
          height: 20px;
          color: $dark-gray;
          opacity: 0.7;

          position: absolute;
          top: 50%;
          left: 50%;

          transform: translate(-45%, -50%) rotate(-90deg);

          transition: transform 0.2s ease;

          &.open {
            transform: translate(-50%, -45%) rotate(0deg);
          }
        }
      }
    }

    .weeks-wrapper {
      transform: translateX(30px);

      .week-row {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        position: relative;

        .check-button {
          min-width: auto;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;

          transform: translate(-35px, -50%);

          svg {
            width: 13px;
            height: 13px;
          }
        }

        .clickable {
          padding: 10px 20px 10px 0;

          display: flex;
          justify-content: space-between;

          &.completed {
            color: $dark-gray;
          }

          .texts {
            width: 100%;

            .week-name {
              display: flex;
              align-items: center;

              &--badge {
                width: 73px;
                height: 14px;
                margin-right: 5px;

                h6 {
                  font-size: 10px;
                }
              }

              &--text {
                white-space: nowrap;

                font-family: VAG;
                font-size: 14px;

                &.current {
                  font-weight: bold;
                  font-size: 15px;
                }
              }
            }

            &--description {
              white-space: nowrap;
              font-size: 12px;

              font-family: VAG;

              &.current {
                font-weight: bold;
              }
            }
          }

          .date-bounds {
            font-size: 12px;
            font-family: Source Sans Pro;
            color: $dark-gray;
          }
        }
      }
    }
  }

  .hr-line {
    width: 100%;
    height: 1px;
    background-color: $alto;
  }
}

.drawer-unit {
  position: relative;

  &--check {
    width: 25px;
    height: 25px;
    color: $friendly-green;

    position: absolute;
    top: 50%;
    left: 0;

    transform: translateY(-50%);
  }

  &--text {
    max-width: 100%;
    white-space: nowrap;
    padding: 15px 0 15px 35px;

    cursor: pointer;
  }
}

.draft-badge {
  position: absolute;
  right: 15px;
  top: 15px;
}