@import 'styles/colors';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: 23px;
  flex-shrink: 0;
  color: $sesame-black;
  transition: all 0.3s ease;
  background: transparent;
  border: 1px solid $sesame-black;
  border-radius: 100%;
  user-select: none;
  cursor: pointer;
  padding-bottom: 1px;
  padding-left: 1px;
  
  .check {
    margin: auto;
  }

  &:hover {
    color: $friendly-green;
    border-color: $friendly-green;
  }

  &.isPreview {
    opacity: 0.5;
  }

  &.checked {
    color: #fff;
    border-color: $friendly-green;
    background-color: $friendly-green;

    &:hover {
      color: #fff;
      border-color: $friendly-green;
    }
  }
}
