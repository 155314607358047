@import 'styles/colors';
@import 'styles/variables';

.sort {
  margin-bottom: 25px;
}

.sort-icon {
  width: 25px;
  height: 25px;

  position: absolute;
  right: 30px;
  top: 125px;
}

.main-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // gap: 10px 30px;
  padding-bottom: calc(#{$mobile-nav-height} + 40px);

  @media (max-width: 1200px) {
    justify-content: space-between;
  }

  .normal-card-wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 24px;

    border: 1px solid $alto;
    border-radius: 15px;
    overflow: hidden;
    // safari fix for overflow: hidden problems
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    opacity: 0;
    animation-name: tileSlideIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;

    @media (max-width: 1200px) {
      max-width: 48%;
    }

    @media (max-width: 576px) {
      max-width: unset;
    }

    .dots-wrapper {
      width: 40px;
      height: 40px;
      background-color: adjust-color($color: $sesame-black, $alpha: -0.9);
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 10px;
      right: 10px;

      z-index: 1;
      transform: rotate(90deg);

      &--icon {
        width: 35px;
        height: 35px;
        color: $secondary-white;
      }
    }

    .image-wrapper {
      width: 100%;
      height: fit-content;
      display: flex;

      position: relative;

      &--image {
        width: 100%;
        height: 100%;
        min-height: 125px;
      }

      &--grade {
        position: absolute;
        bottom: 30px;
        left: 10px;
        color: $secondary-white;
      }

      &--name {
        position: absolute;
        bottom: 10px;
        left: 10px;

        max-width: 90%;
        white-space: nowrap;
        color: $secondary-white;
      }
    }

    .bottom-section {
      width: 100%;
      padding: 10px;

      position: relative;

      &--unit {
        max-width: 70%;
        white-space: nowrap;
        font-size: 16px;
      }

      &--unit-desc {
        max-width: 70%;
        white-space: nowrap;
        color: $dark-gray;
      }

      .week-progress {
        width: 100%;
        height: 40px;
        padding: 8px;
        margin-top: 10px;

        border: 1px solid $alto;
        border-radius: 20px;

        .current-week {
          display: flex;
          align-items: center;
          margin-left: 5px;

          &--name {
            margin-left: 3px;
            white-space: nowrap;
          }

          &--badge {
            transform: scale(0.9);
          }
        }
      }

      .not-started {
        width: 77px;
        height: 22px;
        padding: 3px 0;
        text-align: center;
        color: #737373;
        white-space: nowrap;
        font-size: 12px;
        border-radius: 2px;
        border: 1px solid #737373;
        text-transform: uppercase;

        margin-top: 10px;
      }

      .users-wrapper {
        position: absolute;
        right: 10px;
        top: 10px;

        &--user {
          position: absolute;
        }
      }
    }
  }

  .list-card-wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    margin-bottom: 16px;
    opacity: 0;

    animation-name: listElementSlideIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;

    .image-wrapper {
      width: 56px;
      height: 56px;
      border-radius: 10px;

      flex-shrink: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      &--image {
        width: 100%;
      }
    }

    .texts-wrapper {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      &--classroom {
        white-space: nowrap;
      }

      &--week {
        white-space: nowrap;
        font-size: 11px;
        color: $dark-gray;
      }
    }

    .dots-wrapper {
      margin-left: auto;

      &--icon {
        width: 25px;
        height: 25px;
      }
    }
  }

  .placeholder-classroom {
    position: relative;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    width: 100%;
    height: 250px;
    border-radius: 15px;
    margin-bottom: 24px;

    opacity: 0;
    animation-name: tileSlideIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;

    @media (max-width: 1200px) {
      max-width: 48%;
      height: 280px;
    }

    @media (max-width: 576px) {
      max-width: unset;
    }

    .text-wrapper {
      position: absolute;
      left: 25px;
      bottom: 25px;
    }

    .set-up-classroom {
      width: 190px;
      height: 48px;
      border-radius: 30px;
      background-color: rgba(21, 21, 21, 0.7);

      font-family: Source Sans Pro;
      font-size: 18px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s ease;
      border: 2px solid transparent;
      z-index: 10;

      &:hover {
        border-color: white;
      }
    }
  }
}

.drawer-option {
  display: flex;
  align-items: center;
  padding: 10px 0;

  cursor: pointer;

  &--icon {
    margin-right: 10px;
  }
}

.add-classroom {
  position: fixed;
  right: 30px;
  bottom: calc(#{$mobile-nav-height} + 20px);

  z-index: 5;
}

@keyframes tileSlideIn {
  0% {
    transform: translateY(15%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes listElementSlideIn {
  0% {
    transform: translateX(25%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.school-select {
  height: 66px !important;
  width: 100% !important;

  .dropdown-button {
    width: 50px;
  }
}

.school-value-wrapper {
  display: flex;
  align-items: center;
  padding: 10px;

  .avatar {
    height: 50px;
    width: 50px;
    margin-right: 15px;
  }
}
