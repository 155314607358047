@import 'styles/colors';

.tab-menu-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .menu {
    min-width: 230px;
    min-height: 340px;
    padding: 20px 30px;
    background-color: $concrete;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    .menu-option {
      width: fit-content;
      margin: 10px 0;
      cursor: pointer;

      &.selected {
        text-decoration: underline;
      }
    }
  }
}
