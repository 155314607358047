@import 'styles/variables';

.wrapper {
  padding: 15px 0;
  max-width: 385px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 100%;
    padding: 65px 30px 30px 30px;
  }

  .logo {
    margin: auto;
    margin-bottom: 37px;
    padding: 30px 0;

    @include mobile {
      padding: 30px 0 0 0;
    }
  }

  .reset-button {
    @include mobile {
      width: 100%;
    }
  }
}
