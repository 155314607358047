@import 'styles/colors';
@import 'styles/variables';

.heading-wrapper {
  min-width: 100%;
  height: $tl-navbar-height;
  padding-left: 30px;
  padding-right: 30px;

  display: flex;
  justify-content: space-between;

  position: sticky;
  top: 0;
  left: 0;
  background-color: $secondary-white;
  z-index: 20;
}

.content-left {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
}

.draft-badge {
  margin-left: 5px;
  margin-top: 1px;
}
