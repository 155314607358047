@import 'styles/colors';
@import 'styles/variables';

$wrapper-padding: 20px;
$wrapper-width: 340px;
$wrapper-min-height: 180px;
$wrapper-radius: 15px;
$icon-wrapper-diameter: 70px;

.wrapper {
  width: $wrapper-width;
  min-height: $wrapper-min-height;
  padding: $wrapper-padding;
  border-radius: $wrapper-radius;
  position: relative;
  background-color: $friendly-red-007;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile {
    width: 100%;
    padding: 40px 20px 30px 20px;
    min-height: 140px;
  }

  .wrapper-text {
    margin-top: 5px;
    white-space: pre-wrap;
    text-align: center;
  }

  &--flat {
    min-height: auto;
    padding: 20px 30px 20px 75px;
    width: 100%;
    border-radius: 40px;

    display: inline-block;
    line-height: 28px;

    @include mobile {
      padding: 10px 10px 10px 45px;
      border-radius: 30px;
      line-height: 22px;
    }

    .wrapper-sel-text {
      &--span {
        font-weight: normal;
      }

      @include mobile {
        margin-left: 10px;
        font-size: 14px;
        min-width: 140px;
        font-weight: 700;
      }
    }

    .wrapper-text {
      @include mobile {
        font-size: 14px;
        white-space: nowrap;
        width: 100%;
      }
    }

    &.review {
      padding-left: 25px;

      @include mobile {
        padding-left: 10px;
      }
    }
  }

  .heart-wrapper {
    position: absolute;
    width: $icon-wrapper-diameter;
    height: $icon-wrapper-diameter;
    background-color: $friendly-red;
    border-radius: 50%;

    display: flex;

    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mobile {
      width: 37px;
      height: 37px;
    }

    &--flat {
      height: 50px;
      width: 50px;
      left: 10px;
      top: 10px;

      transform: none;

      @include mobile {
        width: 32px;
        height: 32px;
        top: 5px;
      }
    }

    .heart-icon {
      margin: auto;
      color: #fff;

      @include mobile {
        width: 22px;
        height: 22px;
      }

      &--flat {
        width: 30px;
        height: 30px;

        @include mobile {
          transform: scale(0.7);
        }
      }
    }
  }

  &.review {
    background-color: $concrete;

    &:not(.wrapper--flat) {
      min-height: 160px;

      @include mobile {
        min-height: 100px;
      }
    }

    .heart-wrapper {
      display: none;
    }
  }
}
