@import 'styles/colors';
@import 'styles/variables';

.expired-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: $joyeful-yellow;
  z-index: 100;
  min-height: 72px;
  padding: 10px 40px;
  line-height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 30px;
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    @include mobile {
      right: 10px;
      top: 10px;
      transform: none;
    }
  }
}
