@import 'styles/colors';
@import 'styles/variables';

h1,
h2,
h3 {
  font-weight: inherit;
  font-family: VAG;

  * {
    font-family: VAG;
  }
}

.sel_header {
  background-color: $friendly-red-007;
  width: 100%;
  min-height: 70px;
  border-radius: 40px;
  padding: 20px 30px 20px 75px;
  font-weight: 500;

  position: relative;
  word-break: break-all;

  @include mobile {
    min-height: auto;
    padding: 15px 25px 15px 65px;
    font-size: 14px;
    margin: 20px 0;
    border-radius: 30px;
    line-height: 22px;
  }

  &::before {
    display: inline-block;
    content: 'SEL BIG IDEA:  ';
    font-weight: 700;
    white-space: pre;
  }

  &::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: $friendly-red;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-image: url('../../../../icons/heart-white.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 30px;

    @include mobile {
      width: 40px;
      height: 40px;
      background-size: 21px;
      top: 6px;
    }
  }
}

.sel_header_review {
  background-color: $concrete;
  width: 100%;
  min-height: 70px;
  border-radius: 40px;
  padding: 20px 30px 20px 25px;
  font-weight: 500;

  position: relative;
  word-break: break-all;

  @include mobile {
    min-height: auto;
    padding: 15px 25px 15px 25px;
    font-size: 14px;
    margin: 20px 0;
    border-radius: 30px;
    line-height: 22px;
  }

  &::before {
    display: inline-block;
    content: 'SEL BIG IDEA:  ';
    font-weight: 700;
    white-space: pre;
  }
}

table {
  background-color: $wild-sand;
  border-radius: 5px;
  margin: 25px 0;
  overflow: hidden;
  border-collapse: collapse;

  td {
    padding: 10px 10px;
    vertical-align: baseline;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }

    strong {
      font-weight: bold;
    }

    ul {
      padding: 0 18px;
      margin-top: 0;
      margin-bottom: 0;

      li {
        line-height: 24px;
      }
    }
  }

  th {
    text-align: justify;
    padding: 10px 40px 10px 10px;
    font-size: 18px;
    color: $secondary-white;
    font-weight: unset;

    &:first-child {
      padding-left: 20px;
    }

    @include mobile {
      padding-left: 10px !important;
    }
  }

  @include mobile {
    margin: 0;

    tbody {
      tr {
        max-width: 315px;
      }
    }

    ol {
      margin: 0;
      padding: 20px 0 20px 20px;
    }

    td {
      padding: 15px;
      width: 100% !important;
      min-width: 220px;
      overflow-wrap: break-word;

      ul {
        padding: 0 10px !important;
      }
    }
  }
}

.sel_related {
  th {
    color: $secondary-white;
    font-weight: normal;

    background-color: $friendly-red;
    background-image: url('../../../../icons/heart-white.svg');
    background-repeat: no-repeat;
    background-position: 99%;
    background-size: 28px;

    @include mobile {
      background-position: 97%;
    }
  }
}
