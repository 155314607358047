@import 'styles/colors';

.outer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &.searching {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .sort-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    min-width: fit-content;
    padding: 5px 0;
    position: relative;

    &--arrow {
      width: 20px;
      height: 20px;
      margin-left: 5px;

      transform: rotateZ(90deg);
      transition: transform 0.2s ease;

      &--sorted {
        transform: rotateZ(-90deg);
      }
    }

    &.searching {
      margin: 5px auto 0 0;
    }
  }

  .search-loop {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    pointer-events: all;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .search-input {
    opacity: 0;
    pointer-events: none;
    width: 0;
    height: 40px;
    border: 1px solid $alto;
    border-radius: 34px;
    padding: 0 20px;
    font-size: 15px;
    font-family: Source Sans Pro;

    transition: width 0.3s ease-in;

    &.active {
      opacity: 1;
      pointer-events: all;
      width: 100%;
    }
  }

  .close-icon {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}
