@import 'styles/colors';

.container {
  border: 1px solid $light-gray;
  border-radius: 2px;
  cursor: text;

  transition: all 0.1s ease;

  &:focus-within {
    border-color: $friendly-green;
    box-shadow: 0px 0px 0px 5px rgba(0, 177, 64, 0.2);
  }

  .email-wrap {
    background-color: $friendly-green;
    border-radius: 3px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 5px;
    margin-bottom: 5px;

    .icon {
      opacity: 0.6;
    }

    .email {
      min-height: 18px;
    }

    .delete {
      color: white;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }
}
