@import 'styles/colors';
@import 'styles/variables';

$wrapper-width: 410px;
$mobile-wrapper-width: 315px;
$wrapper-min-height: 65px;

.wrapper {
  width: $wrapper-width;
  min-height: $wrapper-min-height;
  padding: 15px 22px 15px 10px;
  border: 1px solid $light-gray;
  border-radius: 5px;
  transition: 0.3s ease-in-out;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-color: $friendly-red;
    cursor: pointer;
  }

  @media (max-width: $mobile-breakpoint) {
    width: $mobile-wrapper-width;
  }

  .content-left {
    max-width: 90%;
    height: 100%;

    display: flex;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 10px;

      &:first-child {
        // p {
        font-weight: bold;
        // }
      }
    }

    .pdf-icon {
      margin: auto 0;
    }
  }
}

.dark-gray-text {
  color: $dark-gray;
}
