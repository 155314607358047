@import 'styles/colors';

.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &--icon {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 43px;
    left: 15px;
  }

  &--texts {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--small {
      max-width: 60vw;
      text-align: center;
      color: $dark-gray;
    }
  }

  &--check {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 48px;
    right: 30px;

    border-color: $dark-gray;
    color: $dark-gray;

    svg {
      height: 23px;
      width: 23px;
    }
  }
}

.getting-wrapper {
  display: flex;
  flex-direction: column;

  .image-wrapper {
    width: 100vw;

    transform: translateX(-30px);

    display: flex;
    flex-direction: column;

    .week-image {
      width: 100%;
    }

    .colorbar {
      width: 100%;
      height: 12px;
    }
  }

  .bread-crumbs {
    margin-top: 30px;
    color: $dark-gray;
    font-family: Adele;
    opacity: 0.7;
  }

  .week-name {
    margin: 10px 0 15px 0;
  }

  .target-sel {
    margin-top: 40px;
  }

  .plans-text {
    margin: 40px 0 25px 0;
    font-family: Source Sans Pro;
  }

  .lesson-plans {
    width: 100%;
    transform: translateX(30px);

    .activity-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      position: relative;

      .check-button {
        min-width: auto;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;

        transform: translate(-35px, -50%);

        svg {
          width: 13px;
          height: 13px;
        }
      }

      .clickable {
        padding: 10px 20px 10px 0;

        &.completed {
          color: $dark-gray;
        }

        &--sel {
          width: fit-content;
          max-width: 100%;
          padding: 0 5px;
          border-radius: 3px;

          display: flex;
          align-items: center;

          background-color: $friendly-red-007;
        }

        &--name {
          white-space: nowrap;
        }

        &--description {
          white-space: nowrap;
          font-size: 12px;
        }
      }

      .heart-icon {
        width: 13px;
        height: 13px;
        margin-left: 3px;
        color: $friendly-red;
      }
    }
  }

  .hr-line {
    width: 100%;
    height: 1px;
    background-color: $alto;
  }
}
