@import 'styles/colors';
@import 'styles/variables';

.wrapper {
  padding: 15px 0;
  max-width: 385px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 85%;
    margin-bottom: 100px;
  }

  .form {
    display: flex;
    flex-direction: column;
  }
}

.title-select {
  width: 100px;
}

.logo {
  margin: auto;
  margin-bottom: 37px;
  padding: 30px 0;

  @include mobile {
    padding: 30px 0 0 0;
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.date-input {
  width: 170px;
}

.day-picker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
  background: $secondary-white;

  @include mobile {
    width: 85%;
  }
}

.next-step {
  @include mobile {
    width: 150px;
  }
}
